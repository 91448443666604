import React from "react"
import GridItem from "../Grid/GridItem"
import CustomInput from "../CustomInput/CustomInput"
import GridContainer from "../Grid/GridContainer"
import Paper from "@material-ui/core/Paper"
import Button from "../CustomButtons/Button"

const Form = (props) => {
  const {
    handleChange,
    handleChangeBilling,
    values,
    copyToBilling,
    contactFields,
    billingFields,
  } = props

  const rows = [contactFields, billingFields]

  return (
    <Paper style={{ marginTop: "20px", padding: "20px" }}>
      {rows.map((arr, rowIdx) => (
        <GridContainer key={rowIdx} spacing={0}>
          <GridItem xs={12}>
            <h2 style={{ display: "inline", marginRight: "10px" }}>
              {rowIdx === 0 ? "Contact Information" : "Billing Information"}
            </h2>
            {rowIdx === 1 && (
              <Button
                style={{ marginBottom: "12px" }}
                color={"primary"}
                onClick={(e) => {
                  e.preventDefault()
                  copyToBilling()
                }}
              >
                Copy Contact Info
              </Button>
            )}
          </GridItem>
          {arr.map((f, idx) => {
            const value =
              rowIdx === 0 ? values[f.name] : values.billingAddress[f.name]
            return (
              <GridItem key={idx} xs={12} sm={f.fullWidth ? 12 : 6}>
                <CustomInput
                  error={values.fieldErrors[f.name]}
                  labelText={f.label}
                  formControlProps={{
                    fullWidth: true,
                    // className: classes.customFormControlClasses,
                    required: f.required,
                  }}
                  inputProps={{
                    value: value,
                    onChange: f.handler ? handleChangeBilling : handleChange,
                    type: f.type ? f.type : "text",
                    className: "form-control",
                    name: f.name,
                    placeholder: `Enter ${f.label}...`,
                    // multiline: false,
                    autoFocus: false,
                    // rows: f.rows,
                    maxLength: 254,
                    pattern: f.pattern ? f.pattern : "",
                  }}
                />
              </GridItem>
            )
          })}
        </GridContainer>
      ))}
    </Paper>
  )
}

export default Form
