import React, { Component } from "react"
import "./Compose.css"
import axios from "axios"
import * as Auth from "../../../../utils/Auth"
import CustomInput from "../../../CustomInput/CustomInput"
import Button from "@material-ui/core/Button"
import { Input } from "@material-ui/core";

const baseUrl = `${process.env.GATSBY_API_URL}`

export default class Compose extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: "",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = (e) => {
    const key = e.target.name
    const value = e.target.value

    this.setState({
      [key]: value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    let self = this
    const params = "/pmapi/post"
    const recipients = self.getMembersIds()
    const payload = {
      data: {
        message: self.state.message,
        members: recipients,
      },
    }
    axios
      .post(baseUrl + params, payload, Auth.getAuthHeadersApi())
      .then(function (response) {
        // console.log("posted message")
        self.props.conversationCallback(response.data.thread_id)
        self.props.refresh("b")
        self.setState({
          message: "",
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  getMembersIds = () => {
    let memberIds = []
    this.props.members.map((m) => memberIds.push(m.id))
    return memberIds
  }

  render() {
    return (
      <div className="compose">
        <form onSubmit={this.handleSubmit}>
          <Input
            type="text"
            onChange={this.handleInputChange}
            style={{ width: "100%" }}
            required={true}
            inputProps={{
              value: this.state.message,
              onChange: this.handleChange,
              type: "text",
              className: "form-control",
              name: "message",
              placeholder: "Enter Message Here... ",
            }}
            multiline={true}
            rows={3}
          />

          <Button type="submit" color="primary" className="compose-button">
            Send Message
          </Button>
        </form>
      </div>
    )
  }
}
