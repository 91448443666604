import React, { Component } from "react"
import Toolbar from "../Toolbar"
import Message from "../Message"
import moment from "moment"

import "./MessageList.css"
import * as Auth from "../../../../utils/Auth"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RefreshContent } from "react-js-pull-to-refresh"

const MY_USER_ID = Auth.getUuid()
const baseUrl = `${process.env.GATSBY_API_URL}`

export default class MessageList extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { conversation, refresh, refreshMessages } = this.props
    if (conversation !== prevProps.conversation && conversation !== null) {
      this.getMessages()
    } else if (refreshMessages) {
      refresh("m")
      this.getMessages()
    }
    this.scrollToBottom()
  }

  componentDidMount() {
    this._isMounted = true
    const { conversation } = this.props
    if (conversation !== null) {
      this.getMessages()
    }
    this.scrollToBottom()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" })
  }

  getName(arr, uid) {
    const result = arr.find(user => user.id === uid)
    return !result ? "Me" : result.name
  }

  getMessages = () => {
    if (!Auth.isBrowser) {
      return
    }

    let self = this
    self._isMounted && self.setState({ loading: true })
    const endpoint = "/jsonapi/private_message_thread/private_message_thread"
    const conversation = `/${self.props.conversation}`
    const params = "?include=private_messages"
    axios
      .get(
        baseUrl + endpoint + conversation + params,
        Auth.getAuthHeadersJsonapi()
      )
      .then(response => {
        // console.log("messages fetched")
        // console.log(response)
        if (response.data.data.length !== 0) {
          const members = self.props.members
          let messages = response.data.included.map(result => {
            return {
              id: result.id,
              authorName: self.getName(
                members,
                result.relationships.owner.data.id
              ),
              author: result.relationships.owner.data.id,
              message:
                result.attributes.message !== null
                  ? result.attributes.message.processed
                  : "",
              timestamp: result.attributes.created,
            }
          })
          self.setState(
            { loading: false },
            self.props.messagesCallback([...messages])
          )
        } else {
          self.setState({ 'loading': false });
          console.log("no response")
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  renderMessages() {
    let i = 0
    let messageCount = this.props.messages.length
    let messages = []

    while (i < messageCount) {
      let previous = this.props.messages[i - 1]
      let current = this.props.messages[i]
      let next = this.props.messages[i + 1]
      let isMine = current.author === MY_USER_ID
      let currentMoment = moment(current.timestamp)
      let prevBySameAuthor = false
      let nextBySameAuthor = false
      let startsSequence = true
      let endsSequence = true
      let showTimestamp = true

      if (previous) {
        let previousMoment = moment(previous.timestamp)
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        )
        prevBySameAuthor = previous.author === current.author

        if (prevBySameAuthor && previousDuration.as("hours") < 1) {
          startsSequence = false
        }

        if (previousDuration.as("hours") < 1) {
          showTimestamp = false
        }
      }

      if (next) {
        let nextMoment = moment(next.timestamp)
        let nextDuration = moment.duration(nextMoment.diff(currentMoment))
        nextBySameAuthor = next.author === current.author

        if (nextBySameAuthor && nextDuration.as("hours") < 1) {
          endsSequence = false
        }
      }

      messages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
          members={this.props.members}
        />
      )

      // Proceed to the next message.
      i += 1
    }

    return messages
  }

  render() {
    const { isMobile, handleShowConversations } = this.props
    return (
      <div className="message-list">
        <Toolbar
          title="Thread"
          style={{ paddingBottom: "10px" }}
          leftItems={[
            isMobile && (
              <button
                key={"refresh"}
                onClick={() => handleShowConversations(true)}
                className="clear-button"
                type={"submit"}
              >
                <FontAwesomeIcon
                  icon={"arrow-left"}
                  color={"#00acc1"}
                  size={"2x"}
                />
              </button>
            ),
          ]}
        />
        <div className="message-list-container">
          {this.state.loading && <RefreshContent />}
          {this.renderMessages()}
          <div
            className="message-list-scroll-bottom"
            ref={el => {
              this.messagesEnd = el
            }}
          />
        </div>
      </div>
    )
  }
}
