import CustomInput from "../CustomInput/CustomInput"
import React from "react"

function CustomTextInput(props) {
  const { required = true, name, classes, handleChange, value, title, type } = props

  return (
    <CustomInput
      labelText={title}
      formControlProps={{
        fullWidth: true,
        className: classes.customFormControlClasses,
        required: required
      }}
      inputProps={{
        value: value,
        onChange: handleChange,
        type: type !== undefined ? type : "text" ,
        className: "form-control",
        name: name,
        placeholder: "Enter " + title + "...",
        autoFocus: false,
      }}
    />
  )
}

export default CustomTextInput
