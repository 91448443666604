import React from "react"
import { Link } from "gatsby"
import { existingUrls } from "../../hooks/existingUrls"
import VisibilityIcon from "@material-ui/icons/Visibility"

const urlExists = (alias) => {
  const urls = existingUrls()
  for (let i = 0; i < urls.length; i++) {
    if (alias === urls[i].node.path) {
      return true
    }
  }
  return false
}

const style = {
  link: {
    display: "block",
    lineHeight: "24px",
    marginTop: "-10px",
  },
  icon: {
    verticalAlign: "middle",
  },
}

const DirectoryLink = (props) => {
  const { path, selectComponentByName } = props
  return path != null && urlExists(path.alias) ? (
    <>
      <Link style={style.link} to={path.alias}>
        View Directory Listing <VisibilityIcon style={style.icon} />
      </Link>
      <p>
        Share your URL now!{" "}
        <strong>https://centerconsolesnly.net{path.alias}</strong>
      </p>
      Want to be searchable on CCO? <br />
      <a
        href={"#"}
        onClick={(e) => {
          e.preventDefault()
          selectComponentByName("Subscriptions")
        }}
      >
        Click here to select a subscription!
      </a>
    </>
  ) : (
    ""
  )
}

export default DirectoryLink
