import React from "react"
import { generateTimeSeries } from "../../helpers/common"
import GridItem from "../Grid/GridItem"
import GridContainer from "../Grid/GridContainer"
import { Radio } from "@material-ui/core"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    margin: "20px 0 20px 0",
    padding: "20px",
  },
  hours: {
    padding: "10px 0",
    borderRadius: "4px",
  },
}))

const Hours = (props) => {
  const { days, hours, handleChange, errors, hourOptions } = props
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <GridContainer
        spacing={0}
        alignContent={"center"}
        alignItems={"center"}
        justify={"center"}
      >
        <h3>Hours of Operation</h3>
        {days.map((day, idx) => (
          <GridItem key={idx} xs={12}>
            <GridContainer
              spacing={0}
              alignContent={"center"}
              alignItems={"center"}
              className={classes.hours}
              style={{ backgroundColor: idx % 2 ? "#eeeeee" : "inherit" }}
            >
              <GridItem xs={3} md={2}>
                <h5>{day}</h5>
              </GridItem>

              <GridItem xs={9} md={4}>
                <RadioGroup
                  style={{ display: "inline" }}
                  aria-label="status"
                  name="status"
                  onChange={(e) => {
                    handleChange(e, idx)
                  }}
                  value={hours[idx].status}
                >
                  <FormControlLabel
                    style={{ marginRight: "10px", marginLeft: "0" }}
                    value="closed"
                    control={<Radio />}
                    label="Closed"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="open"
                    control={<Radio />}
                    label="Open"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="always"
                    control={<Radio />}
                    label="24 Hours"
                    labelPlacement="top"
                  />
                </RadioGroup>
              </GridItem>
              {hours[idx].status === "open" && (
                <>
                  <GridItem xs={6} md={2}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="open">Opens:</InputLabel>
                      <Select
                        labelId="open"
                        id="open"
                        name={"open"}
                        value={hours[idx].open}
                        onChange={(e) => {
                          handleChange(e, idx)
                        }}
                      >
                        {hourOptions.map((value, i) => {
                          return (
                            <MenuItem key={i} value={value}>
                              {value}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={6} md={2}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="close">Closes:</InputLabel>
                      <Select
                        labelId="close"
                        id="close"
                        name="close"
                        value={hours[idx].close}
                        onChange={(e) => {
                          handleChange(e, idx)
                        }}
                      >
                        {hourOptions.map((value, idx) => {
                          return (
                            <MenuItem key={idx} value={value}>
                              {value}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    <span
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "red",
                      }}
                    >
                      <p>{errors[idx]}</p>
                    </span>
                  </GridItem>
                </>
              )}
            </GridContainer>
          </GridItem>
        ))}
      </GridContainer>
    </Paper>
  )
}

export default Hours
