import React, { Component } from "react"
import { getAuthHeadersApi, isBrowser } from "../../utils/Auth"
import axios from "axios"
import DashboardMenu from "./menu"
// Components for menu
import Profile from "../Profile"
import UserPosts from "../UserPosts"
import Braintree from "../Braintree"
import UserListings from "../UserListings"
import PrivateMessages from "../PrivateMessages"
import Directory from "../Directory"
import DealerBoatListing from "../PostBoatListing/dealerPostBoatListing"
// @material-ui/core components
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import { RefreshContent } from "react-js-pull-to-refresh"

const baseUrl = `${process.env.GATSBY_API_URL}`

const userOptions = [
  { name: "Profile", comp: Profile },
  { name: "Posts", comp: UserPosts },
  { name: "Listings", comp: UserListings },
  { name: "Messages", comp: PrivateMessages },
  { name: "Directory Listing", comp: Directory },
  { name: "Subscriptions", comp: Braintree },
]

const dealerOptions = [{ name: "Dealer Boat Listing", comp: DealerBoatListing }]

const RenderComponent = ({ component: Component, ...props }) => {
  if (!isBrowser) return

  return (
    <Component selectComponentByName={props.selectComponentByName} {...props} />
  )
}

class Dashboard extends Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      component: Profile,
      selectedIndex: 0,
      options: userOptions,
      loading: true,
    }
    this.setSelectedIndex = this.setSelectedIndex.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.checkDealerAccess()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  setSelectedIndex = (index) => {
    const { options } = this.state
    this._isMounted &&
    this.setState({
      selectedIndex: index,
      component: options[index].comp,
    })
  }

  selectComponentByName = (name) => {
    const { options } = this.state
    let index = 0
    options.map((o, idx) => {
      if (o.name === name) {
        index = idx
      }
    })
    this.setSelectedIndex(index)
  }

  checkDealerAccess = () => {
    const endpoint = "/user/current_user_role?_format=json"
    const self = this
    axios
      .get(baseUrl + endpoint, getAuthHeadersApi())
      .then((res) => {
        if (res.data[0] !== undefined) {
          const { roles } = res.data[0]
          if (roles.indexOf("Dealer") !== -1) {
            self._isMounted &&
            self.setState({
              options: [...self.state.options, ...dealerOptions],
              loading: false,
            })
          } else {
            self._isMounted &&
            self.setState({
              loading: false,
            })
          }
        }
      })
      .catch((e) => {
        console.log(e)
        self._isMounted &&
        self.setState({
          loading: false,
        })
      })
  }

  render() {
    const { selectedIndex, component, options, loading } = this.state
    const { storeContext } = this.props
    return !loading && storeContext.checkout.id !== undefined ? (
      <GridContainer spacing={3} direction="row" justify="center">
        <GridItem md={3} xs={12}>
          <DashboardMenu
            options={options}
            selected={selectedIndex}
            setSelectedIndex={this.setSelectedIndex}
          />
        </GridItem>
        <GridItem md={9} xs={12}>
          <RenderComponent
            selectComponentByName={this.selectComponentByName}
            component={component}
            {...this.props}
          />
        </GridItem>
      </GridContainer>
    ) : (
      <RefreshContent />
    )
  }
}

export default Dashboard
