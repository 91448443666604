import React, { useEffect, useState } from "react"
import qs from "qs"
import { Paper } from "@material-ui/core"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import { getAuthHeadersJsonapi, getUuid, isBrowser } from "../../utils/Auth"
import axios from "axios"
import { RefreshContent } from "react-js-pull-to-refresh"
import { CloudinaryMediaSingle } from "../CloudinaryMedia"
import Button from "../CustomButtons/Button"
import { Link } from "gatsby"
import moment from "moment"

const baseUrl = `${process.env.GATSBY_API_URL}`

const d = new Date()
const threshold = d.setDate(d.getDate() + 5)

const style = {
  root: {
    margin: "86px 0 16px 0",
    padding: "20px",
  },
  container: {
    padding: "20px",
  },
  title: {
    marginTop: 0,
  },
}

const Renew = (props) => {
  const [loading, setLoading] = useState(true)
  const [listing, setListing] = useState(null)
  const [isRenewable, setIsRenewable] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [newExpiration, setNewExpiration] = useState("")
  const { location } = props
  const urlVariable = qs.parse(location.search.slice(1))

  useEffect(() => {
    urlVariable.id != null && getUserListings()
  }, [])

  const getUserListings = () => {
    if (!isBrowser) return

    const endpoint = `/jsonapi/node/listing?filter[uid.id][value]=${getUuid()}&filter[id][value]=`

    axios
      .get(baseUrl + endpoint + urlVariable.id, getAuthHeadersJsonapi())
      .then(function (response) {
        const data = response.data.data.length > 0 ? response.data.data[0] : []
        console.log(data)
        if (data) {
          const expiration = Date.parse(data.attributes.unpublish_on)
          setListing(data)
          setIsRenewable(expiration > Date.now() && expiration < threshold)
        }
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
      .catch(function (error) {
        // console.log(error)
        setLoading(false)
      })
  }

  const renewListing = () => {
    if (!isBrowser) return

    const endpoint = "/extend_expiration"
    const payload = { uuid: urlVariable.id }

    axios
      .post(baseUrl + endpoint, payload, getAuthHeadersJsonapi())
      .then(function (response) {
        // console.log(response)
        setNewExpiration(response.data.expires)
      })
      .catch(function (error) {
        // console.log(error)
      })
  }

  return (
    <Paper style={style.root}>
      <GridContainer spacing={5}>
        {loading ? (
          <GridItem xs={12}>
            <RefreshContent />
          </GridItem>
        ) : (
          <>
            {listing != null ? (
              <GridContainer spacing={5} style={style.container}>
                <GridItem xs={12} md={4}>
                  <Link to={listing.attributes.path.alias}>
                    <CloudinaryMediaSingle
                      styles={{ borderRadius: "4px" }}
                      image={JSON.parse(listing.attributes.field_cloudinary)[0]}
                      transformations={"q_auto,h_400"}
                    />
                  </Link>
                </GridItem>
                <GridItem xs={12} md={8}>
                  <h2 style={style.title}>{listing.attributes.title}</h2>
                  <h5>{listing.attributes.body.summary}</h5>
                  {!newExpiration && (
                    <p>
                      Expires:{" "}
                      {moment(listing.attributes.unpublish_on).format("L")}
                    </p>
                  )}
                  {!newExpiration && (
                    <>
                      {isRenewable ? (
                        <Button
                          color={"primary"}
                          style={{ marginTop: "20px" }}
                          onClick={(e) => {
                            e.preventDefault()
                            setDisableButton(true)
                            renewListing()
                          }}
                          disabled={disableButton}
                        >
                          Renew Now
                        </Button>
                      ) : (
                        <h4 style={{ color: "red", marginTop: "30px" }}>
                          This listing is not ready to renew just yet. You'll
                          receive an email when it's time.
                        </h4>
                      )}
                    </>
                  )}

                  {newExpiration && (
                    <>
                      <h4 style={{ color: "green" }}>
                        <strong>CONGRATULATIONS!</strong>
                      </h4>
                      You have successfully renewed your listing! <br />
                      Your New Expiration is:{" "}
                      {moment.unix(parseInt(newExpiration)).format("L")}
                    </>
                  )}
                </GridItem>
              </GridContainer>
            ) : (
              <GridItem xs={12} style={{paddingBottom: "40px"}}>
                <h3 style={{ color: "red", fontWeight: "700" }}>
                  UH, OH. IT SEEMS THERE IS A SLIGHT PROBLEM
                </h3>
                <h4>
                  The listing you have tried to renew is either not yours or
                  expired.
                </h4>
                <span>
                  If you feel you have received this message in error, please
                  send us a <Link to={"/contact"}>message here </Link>for
                  further assistance. Thank you.
                </span>
                <br />
              </GridItem>
            )}
          </>
        )}
      </GridContainer>
    </Paper>
  )
}

export default Renew
