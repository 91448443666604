import React, { Component } from "react"
import ConversationList from "../ConversationList"
import MessageList from "../MessageList"
import ConversationSearch from "../ConversationSearch"
import Compose from "../Compose"
import "./Messenger.css"

export const isBrowser = typeof window !== "undefined"

export default class Messenger extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      conversation: null,
      members: [],
      messages: [],
      refreshMessages: false,
      refreshConversations: false,
      showConversations: true,
    }
    this.conversationCallback = this.conversationCallback.bind(this)
    this.memberCallback = this.memberCallback.bind(this)
    this.messagesCallback = this.messagesCallback.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  conversationCallback = id => {
    this._isMounted &&
      this.setState({
        conversation: id,
      })
  }

  memberCallback = members => {
    this._isMounted &&
      this.setState({
        members: members,
      })
  }

  messagesCallback = messages => {
    this._isMounted &&
      this.setState({
        messages: messages,
      })
  }

  handleShowConversations = action => {
    this._isMounted &&
      this.setState({
        showConversations: action,
      })
  }

  refresh = a => {
    this._isMounted &&
      this.setState({
        refreshMessages:
          ["m", "b"].includes(a) && !this.state.refreshMessages,
        refreshConversations:
          ["c", "b"].includes(a) && !this.state.refreshConversations,
      })
  }

  mobileScrollMaxHeight = () => {
    if (!isBrowser) {
      return
    }
    const totalMenuHeights = this.props.isMobile ? 290 : 250
    return window.innerHeight - totalMenuHeights
  }

  renderConversationSearch = () => {
    const { isMobile } = this.props
    return (
      this.state.showConversations && (
        <ConversationList
          isMobile={isMobile}
          refresh={this.refresh}
          refreshConversations={this.state.refreshConversations}
          conversation={this.state.conversation}
          memberCallback={this.memberCallback}
          messagesCallback={this.messagesCallback}
          conversationCallback={this.conversationCallback}
          handleShowConversations={this.handleShowConversations}
        />
      )
    )
  }

  renderConversationOrMessageList = () => {
    const { isMobile } = this.props
    return !this.state.conversation ? (
      <ConversationSearch
        isMobile={isMobile}
        members={this.state.members}
        conversation={this.state.conversation}
        memberCallback={this.memberCallback}
        conversationCallback={this.conversationCallback}
        handleShowConversations={this.handleShowConversations}
      />
    ) : (
      <MessageList
        isMobile={isMobile}
        refresh={this.refresh}
        refreshMessages={this.state.refreshMessages}
        messages={this.state.messages}
        members={this.state.members}
        conversation={this.state.conversation}
        messagesCallback={this.messagesCallback}
        handleShowConversations={this.handleShowConversations}
      />
    )
  }

  renderFooter = () => {
    return (
      <div className="footer">
        <Compose
          members={this.state.members}
          refresh={this.refresh}
          conversationCallback={this.conversationCallback}
        />
      </div>
    )
  }

  render() {
    const { isMobile } = this.props
    return (
      <div className="messenger">
        <div
          className="scrollable sidebar"
          style={{
            height: isMobile ? this.mobileScrollMaxHeight() : null,
          }}
        >
          {this.renderConversationSearch()}
        </div>
        {(!isMobile || !this.state.showConversations) && (
          <div className="fixed-content-container">
            <div
              className="scrollable content"
              style={{ height: this.mobileScrollMaxHeight() }}
            >
              {this.renderConversationOrMessageList()}
            </div>
            {this.renderFooter()}
          </div>
        )}
        {isMobile && this.renderFooter()}
      </div>
    )
  }
}
