import React from "react"
import DropIn from "braintree-web-drop-in-react/lib"
import { List } from "react-content-loader"
import Button from "../CustomButtons/Button"

class PaymentMethods extends React.Component {
  _isMounted = false
  instance
  constructor(props) {
    super(props)
    this.state = {
      nonce: null,
      clientToken: null,
    }
    this.clearSelectedPayment = this.clearSelectedPayment.bind(this)
    this.requestPaymentNonce = this.requestPaymentNonce.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  requestPaymentNonce() {
    const { handleNonce, handlePaymentErrors } = this.props
    const additionalErrorMessage =
      " Please select a payment method below or enter a new one to proceed."
    // Send the nonce to your server
    if (this.instance.isPaymentMethodRequestable()) {
      this.instance.requestPaymentMethod(function (err, payload) {
        if (err) {
          handlePaymentErrors(err.message + additionalErrorMessage)
          return
        }
        // console.log(payload)
        handleNonce(payload.nonce)
      })
    } else {
      handlePaymentErrors(additionalErrorMessage)
    }
  }

  clearSelectedPayment() {
    const { handleClearSelectedPayment } = this.props
    this.instance.clearSelectedPaymentMethod()
    handleClearSelectedPayment()
  }

  render() {
    const {
      clientToken,
      error,
      createCustomer,
      disableSubmit,
      nonce,
    } = this.props
    if (!clientToken) {
      return <List height={"200px"} />
    } else {
      return (
        <div>
          {error && <span style={{ color: "red" }}>{error}</span>}
          <DropIn
            options={{
              authorization: clientToken,
            }}
            preselectVaultedPaymentMethod={false}
            onInstance={(instance) => (this.instance = instance)}
            onPaymentMethodRequestable={this.requestPaymentNonce}
          />
          {clientToken && (
            <div style={{ float: "right" }}>
              {nonce && (
                <Button
                  color={"danger"}
                  onClick={(e) => {
                    e.preventDefault()
                    this.clearSelectedPayment()
                  }}
                >
                  Clear Selected
                </Button>
              )}
              <Button
                style={{ marginRight: "6px" }}
                color={"primary"}
                onClick={(e) => {
                  e.preventDefault()
                  createCustomer()
                }}
                disabled={disableSubmit()}
              >
                Create Subscription
              </Button>
            </div>
          )}
        </div>
      )
    }
  }
}

export default PaymentMethods
