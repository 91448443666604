import React from "react"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import Card from "../Card/Card"
import { CardContent } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import Form from "./form"

const formatKey = (key) => {
  return key
    .replace("_", " ")
    .replace(/\b[a-zA-Z]/g, (match) => match.toUpperCase())
}

const Customer = (props) => {
  if (props.customer) {
    return <ContactData {...props} />
  } else {
    return <Form {...props} />
  }
}

const ContactData = (props) => {
  const {
    customer: {
      firstName,
      lastName,
      company,
      website,
      email,
      phone,
      addresses,
    },
  } = props

  const data1 = {
    name: `${firstName} ${lastName}`,
    company: company,
    website: website,
    email: email,
    phone_number: phone,
  }

  const data2 = {
    address: addresses[0].streetAddress,
    address_2: addresses[0].extendedAddress,
    city: addresses[0].locality,
    state: addresses[0].region,
    zip_code: addresses[0].postalCode,
  }

  return (
    <GridContainer spacing={0} alignContent={"center"}>
      <GridItem xs={12} sm={6}>
        <Card>
          <CardContent>
            {Object.entries(data1).map(
              ([key, value], idx) =>
                value && <Text key={idx} title={formatKey(key)} text={value} />
            )}
          </CardContent>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6}>
        <Card>
          <CardContent>
            {Object.entries(data2).map(
              ([key, value], idx) =>
                value && <Text key={idx} title={formatKey(key)} text={value} />
            )}
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

const Text = (props) => {
  const { title, text } = props
  return (
    <React.Fragment>
      <Typography variant="body2" color="textSecondary" component="p">
        {title}
      </Typography>
      <Typography gutterBottom variant="h6" component="h2">
        {text}
      </Typography>
    </React.Fragment>
  )
}

export default Customer
