import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"

const style = {
  confirmationButton: {
    padding: "8px",
    color: "white",
    backgroundColor: "green",
    width: "150px",
    borderRadius: "4px",
    fontSize: "large",
  },
}

const ModalSuccess = (props) => {
  const { update, success, message, subMessage } = props
  setTimeout(() => {
    update()
  }, 5000)

  return (
    <>
      {success && (
        <SweetAlert
          success
          title="Good Job!!"
          onConfirm={() => {
            update()
          }}
          onCancel={() => {
            update()
          }}
          confirmBtnStyle={style.confirmationButton}
        >
          {message}
          <br />
          <small>{subMessage}</small>
        </SweetAlert>
      )}
    </>
  )
}

export default ModalSuccess
