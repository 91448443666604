import React, { Component } from "react"
import { getUuid } from "../../../../utils/Auth"
import TimeAgo from "react-timeago"
import { CloudinaryMediaSingle } from "../../../CloudinaryMedia"
import "./ConversationListItem.css"

const style = {
  active: {
    backgroundColor: "#dddddd",
  },
  inactive: {
    backgroundColor: "inherit",
  },
}

export default class ConversationListItem extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      details: [],
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      this.render()
    }
  }

  namesJoined = (details) => {
    let userNames = []
    details.map((user, idx) => {
      getUuid() !== user.id && (userNames[idx] = user.name)
    })
    let filtered = userNames.filter(function (el) {
      return el != null
    })
    return filtered.length > 1 ? filtered.join(", ") : filtered[0]
  }

  isActive = (id) => {
    return this.props.active === id
  }

  render() {
    const { details, updated, id } = this.props.data
    console.log()
    return (
      <div
        className="conversation-list-item"
        style={this.isActive(id) ? style.active : style.inactive}
      >
        {details.map(
          (user, idx) =>
            getUuid() !== user.id && (
              <div key={idx} className="conversation-photo">
                <CloudinaryMediaSingle
                  image={{ pid: `${user.avatar}.png`, resourceType: "image" }}
                  transformations={"w_600,h_600,c_crop,g_face,r_max/w_50"}
                  alt={user.name}
                />
              </div>
            )
        )}
        <div className="conversation-info">
          <h1 className="conversation-title">{this.namesJoined(details)}</h1>
          <p className="conversation-snippet">
            <TimeAgo date={updated} />
          </p>
        </div>
      </div>
    )
  }
}
