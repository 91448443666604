import React, { Component } from "react"
import axios from "axios"
import * as Options from "./selectOptions"
import CustomSelectInput from "./customSelectInput"
import CustomTextInput from "./customTextInput"
import { Link, navigate } from "gatsby"
import { getYears, truncateString } from "../../helpers/common"
import { getAuthHeadersJsonapi, getEmail } from "../../utils/Auth"
import { RefreshContent } from "react-js-pull-to-refresh"
import arrayMove from "array-move"
import SortableImages from "../SortableImages"
// @material-ui Core
import FormControlLabel from "@material-ui/core/FormControlLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Checkbox from "@material-ui/core/Checkbox"
import Check from "@material-ui/icons/Check"
// Material Kit components
import GridContainer from "../Grid/GridContainer.jsx"
import GridItem from "../Grid/GridItem.jsx"
import CardHeader from "../Card/CardHeader"
import Button from "../CustomButtons/Button.jsx"
import Card from "../Card/Card.jsx"
// styles
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import Upload from "../Upload"
import Message from "./message"
import { Input } from "@material-ui/core"
import CustomInput from "../CustomInput/CustomInput"

const baseUrl = `${process.env.GATSBY_API_URL}`
const params = "/jsonapi/node/listing"

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
  button: {
    fontSize: "14px",
    display: "inline-block",
    height: "36px",
    minWidth: "88px",
    padding: "6px 16px",
    lineHeight: "1.42857143",
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "pointer",
    border: "0",
    borderRadius: "2px",
    background: "rgb(0, 172, 193)",
    color: "#fff",
    outline: "0",
  },
}

class ListBoat extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      images: [],
      title: "",
      description: "",
      condition: "",
      electronics: [],
      engine_brand: "",
      engine_count: "",
      engine_hours: "",
      engine_horsepower: "",
      engine_model: "",
      engine_type: "",
      engine_year: "",
      features: [],
      for_sale_by: "",
      freshwater_capacity: "",
      fuel_capacity: "",
      fuel_type: "",
      hull_type: "",
      length_overall: "",
      beam: "",
      manufacturer: "",
      model: "",
      price: "",
      trailer: "",
      year: "",
      youtube_link: "",
      success: "",
      error: "",
      redirect: "/profile",
      email: "",
      phone_number: "",
      address: "",
      vessel_type: "",
      uploadComplete: false,
      reorderComplete: false,
      messageOpen: false,
      submittingListing: false,
      agree: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const key = event.target.name
    const value = event.target.value

    this.setState({
      [key]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    this._isMounted && this.setState({ submittingListing: true })
    this.submitPost()
  }

  handleToggle() {
    const { agree } = this.state
    this._isMounted && this.setState({ agree: !agree })
  }

  componentDidMount() {
    this._isMounted = true
    this.setState({ email: getEmail() })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  uploadCallback = r => {
    if (this._isMounted && r.data.public_id !== undefined) {
      const publicId = r.data.public_id
      const type = r.data.resource_type
      this.setState({
        images: [...this.state.images, { pid: publicId, resourceType: type }],
      })
    }
  }

  uploadCompleteCallback = () => {
    this._isMounted &&
      setTimeout(() => {
        this.setState({ uploadComplete: true })
      }, 2000)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this._isMounted &&
      this.setState(({ images }) => ({
        images: arrayMove(images, oldIndex, newIndex),
      }))
  }

  success = () => {
    setTimeout(() => {
      this._isMounted &&
        this.setState({ messageOpen: false }, () => {
          navigate(`/`)
        })
    }, 1000)
  }

  submitPost = () => {
    let self = this
    const {
      images,
      description,
      condition,
      electronics,
      engine_brand,
      engine_count,
      engine_hours,
      engine_horsepower,
      engine_model,
      engine_type,
      engine_year,
      features,
      for_sale_by,
      freshwater_capacity,
      fuel_capacity,
      fuel_type,
      hull_type,
      length_overall,
      beam,
      manufacturer,
      model,
      price,
      trailer,
      year,
      youtube_link,
      email,
      phone_number,
      address,
      vessel_type,
      agree,
    } = self.state

    if (!agree) {
      self.setState({
        submittingListing: false,
        success: "",
        error: "You must agree to the Terms of Use before posting a listing",
      })
      return
    }

    const required = [
      condition,
      engine_brand,
      engine_count,
      engine_hours,
      engine_horsepower,
      engine_model,
      engine_type,
      engine_year,
      for_sale_by,
      freshwater_capacity,
      fuel_capacity,
      fuel_type,
      hull_type,
      length_overall,
      beam,
      manufacturer,
      model,
      price,
      trailer,
      year,
      email,
      phone_number,
      address,
      vessel_type,
    ]

    if (required.includes("")) {
      self._isMounted &&
        self.setState({
          submittingListing: false,
          error:
            "You are missing required fields. Please go back and select or fill them in before submitting.",
        })
      return
    }

    const title = `${condition} ${year} ${manufacturer} ${model}`

    const payload = {
      data: {
        type: "node--listing",
        attributes: {
          entity_type: "node",
          title: title,
          body: {
            value: description,
            format: "plain_text",
            summary: `${truncateString(description, 80)}`,
          },
          field_beam: beam,
          field_condition: condition,
          field_electronics: electronics,
          field_engine_brand: engine_brand,
          field_engine_count: engine_count,
          field_engine_hours: engine_hours,
          field_engine_horsepower: engine_horsepower,
          field_engine_model: engine_model,
          field_engine_type: engine_type,
          field_engine_year: engine_year,
          field_features: features,
          field_for_sale_by: for_sale_by,
          field_freshwater_capacity: freshwater_capacity,
          field_fuel_capacity: fuel_capacity,
          field_fuel_type: fuel_type,
          field_hull_type: hull_type,
          field_length_overall: length_overall,
          field_manufacturer: manufacturer,
          field_model: model,
          field_price: price,
          field_trailer: trailer,
          field_year: year,
          field_youtube_link: youtube_link,
          field_listing_email: email,
          field_phone_number: phone_number,
          field_address: address,
          field_type: vessel_type,
          field_cloudinary: JSON.stringify(images),
        },
      },
    }

    axios
      .post(baseUrl + params, payload, getAuthHeadersJsonapi())
      .then(function(response) {
        // console.log(response)
        self._isMounted &&
          self.setState({
            images: [],
            success: true,
            message: "",
            error: "",
            messageOpen: true,
            submittingListing: false,
          })
      })
      .catch(function(error) {
        // console.log(error.response)
        let errorResponse = error.response
        self._isMounted && self.setState({ error: errorResponse })
      })
  }

  menuItems = names => {
    const { classes } = this.props
    return names.map((name, idx) => {
      return (
        <MenuItem
          key={idx}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={name}
        >
          {name}
        </MenuItem>
      )
    })
  }

  render() {
    const { classes } = this.props
    const {
      images,
      uploadComplete,
      reorderComplete,
      messageOpen,
      submittingListing,
    } = this.state

    return (
      <GridContainer
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ padding: "1.5rem 16px" }}
      >
        <Message open={messageOpen} success={this.success} />
        <Card style={style.cardBorder}>
          <CardHeader
            plain
            color="primary"
            className={`${classes.textCenter} ${classes.cardLoginHeader}`}
          >
            <h3 className={classes.cardTitleWhite}>List My Boat Now!</h3>
            {!uploadComplete && (
              <p className={classes.cardTitleWhite}>
                Selling your New / Pre-owned boat or looking for some extra
                income and want to rent yours out? Upload pictures, enter
                details and post your listing now!
                <br />
              </p>
            )}
            <h5 className={classes.cardTitleWhite}>
              {!uploadComplete
                ? "Upload Images"
                : !reorderComplete && images.length > 1
                ? "Arrange Image Order"
                : "Enter Boat Information"}
            </h5>
          </CardHeader>
          {!uploadComplete ? (
            <GridItem style={{ textAlign: "center" }}>
              <br />
              <Upload
                limit={30}
                callback={this.uploadCallback}
                completedCallback={this.uploadCompleteCallback}
                buttonText={"Submit Image(s)"}
                filesText={"Image"}
              />
            </GridItem>
          ) : !reorderComplete && images.length > 1 ? (
            <div style={{ textAlign: "center" }}>
              <SortableImages
                images={images}
                onSortEnd={this.onSortEnd}
                axis="xy"
              />
              <button
                onClick={e => {
                  e.preventDefault()
                  this._isMounted && this.setState({ reorderComplete: true })
                }}
                style={style.button}
              >
                Enter Listing Details
              </button>
            </div>
          ) : (
            <form onSubmit={this.handleSubmit}>
              <GridContainer style={{ padding: "0 20px" }}>
                <GridItem xs={12} className={classes.mrAuto}>
                  <h3>General Information:</h3>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems(Options.condition)}
                    title={"Listing Type"}
                    name={"condition"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.condition}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems(getYears())}
                    title={"Year"}
                    name={"year"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.year}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems(Options.manufacturers)}
                    title={"Manufacturer"}
                    name={"manufacturer"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.manufacturer}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomTextInput
                    title={"Model"}
                    name={"model"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.model}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomTextInput
                    title={
                      "Price(Numbers only. No '$' or ',') Enter 0 to omit price"
                    }
                    name={"price"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.price}
                    type={"number"}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems(Options.vesselType)}
                    title={"Vessel Type"}
                    name={"vessel_type"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.vessel_type}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems(Options.forSaleBy)}
                    title={"For Sale By"}
                    name={"for_sale_by"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.for_sale_by}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems(Options.trailer)}
                    title={"Trailer"}
                    name={"trailer"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.trailer}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} className={classes.mrAuto}>
                  <h3>Specifications:</h3>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomTextInput
                    title={"Length Overall (ft)"}
                    name={"length_overall"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.length_overall}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomTextInput
                    title={"Beam (ft)"}
                    name={"beam"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.beam}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems(Options.hullType)}
                    title={"Hull Type"}
                    name={"hull_type"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.hull_type}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems(Options.fuelType)}
                    title={"Fuel Type"}
                    name={"fuel_type"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.fuel_type}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomTextInput
                    title={"Freshwater Capacity (gal)"}
                    name={"freshwater_capacity"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.freshwater_capacity}
                    type={"number"}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomTextInput
                    title={"Fuel Capacity (gal)"}
                    name={"fuel_capacity"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.fuel_capacity}
                    type={"number"}
                  />
                </GridItem>
                <GridItem xs={12} className={classes.mrAuto}>
                  <h3>Propulsion:</h3>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems(Options.engine_type)}
                    title={"Engine(s) Type"}
                    name={"engine_type"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.engine_type}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems(getYears())}
                    title={"Engine(s) Year"}
                    name={"engine_year"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.engine_year}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems(Options.engine_manufacturers)}
                    title={"Engine(s) Manufacturer"}
                    name={"engine_brand"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.engine_brand}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomTextInput
                    title={"Engine(s) Model"}
                    name={"engine_model"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.engine_model}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomTextInput
                    title={"Engine(s) Hours"}
                    name={"engine_hours"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.engine_hours}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomTextInput
                    title={"Engine Horsepower (each)"}
                    name={"engine_horsepower"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.engine_horsepower}
                    type={"number"}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomSelectInput
                    options={this.menuItems([1, 2, 3, 4, 5, 6])}
                    title={"Engine(s) Total"}
                    name={"engine_count"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.engine_count}
                    multiple={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomInput
                    labelText={"Total Horsepower"}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                      disabled: true,
                    }}
                    inputProps={{
                      value:
                        this.state.engine_count * this.state.engine_horsepower,
                      type: "text",
                      className: "form-control",
                      placeholder: "0",
                      autoFocus: false,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <h3>Electronics:</h3>
                  <CustomSelectInput
                    options={this.menuItems(Options.electronics)}
                    title={"Electronics"}
                    name={"electronics"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.electronics}
                    multiple={true}
                    required={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <h3>Features:</h3>
                  <CustomSelectInput
                    options={this.menuItems(Options.features)}
                    title={"Features"}
                    name={"features"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.features}
                    multiple={true}
                    required={false}
                  />
                </GridItem>
                <GridItem xs={12} className={classes.mrAuto}>
                  <h3>Contact Information:</h3>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomTextInput
                    title={"Phone Number"}
                    name={"phone_number"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.phone_number}
                    type={"tel"}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
                  <CustomTextInput
                    title={"Email"}
                    name={"email"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.email}
                    type={"email"}
                  />
                </GridItem>
                <GridItem xs={12} className={classes.mrAuto}>
                  <h3>Listing Address (Full address or City, State):</h3>
                  <CustomTextInput
                    title={"Listing Address"}
                    name={"address"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.address}
                  />
                </GridItem>
                <GridItem xs={12} className={classes.mrAuto}>
                  <h3>Description:</h3>
                  <Input
                    type="text"
                    name={"description"}
                    value={this.state.description}
                    onChange={this.handleChange}
                    placeholder={
                      "Some great details, history or additional features here..."
                    }
                    style={{ width: "100%" }}
                    required={true}
                    inputProps={{ maxLength: 5000 }}
                    multiline={true}
                    rows={10}
                  />
                </GridItem>
                <GridItem xs={12} className={classes.mrAuto}>
                  <h3>Miscellaneous:</h3>
                  <CustomTextInput
                    title={"Youtube Video Url"}
                    name={"youtube_link"}
                    classes={classes}
                    handleChange={this.handleChange}
                    value={this.state.youtube_link}
                    required={false}
                  />
                </GridItem>

                <GridItem xs={12} className={classes.mrAuto}>
                  <div className="form-group messages">
                    <p className="success">{this.state.success}</p>
                    <p
                      style={{ color: "red" }}
                      dangerouslySetInnerHTML={{ __html: this.state.error }}
                    />
                  </div>
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle(1)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={
                      <span>
                        I have read and agree to the{" "}
                        <a href={"/terms-of-use"} target="_blank">
                          Terms of Use
                        </a>
                        .
                      </span>
                    }
                  />
                  <div className={classes.textCenter}>
                    {submittingListing && <RefreshContent />}
                    <Button
                      type="submit"
                      round
                      color="primary"
                      disabled={submittingListing}
                    >
                      Submit Boat Listing
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </form>
          )}
        </Card>
      </GridContainer>
    )
  }
}

export default withStyles(javascriptStyles)(ListBoat)
