import React, { useState, useEffect } from "react"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Button from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Popper from "@material-ui/core/Popper"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    marginRight: theme.spacing(2),
    width: "100%",
  },
  horizontalMenu: {
    display: "inline-flex",
  },
  popper: {
    zIndex: 10,
  },
  menuButton: {
    padding: "6px 24px",
    width: "178px",
    height: "36px",
  },
  mobileMenu: {
    margin: "20px 0 0 25%",
  },
}))

const DashboardMenu = (props) => {
  const classes = useStyles()
  const { options, selected, setSelectedIndex } = props
  const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery("(max-width:768px)")
  const anchorRef = React.useRef(null)
  const prevOpen = React.useRef(open)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }
  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  return (
    <div className={classes.root}>
      {!isMobile ? (
        <Paper className={classes.paper}>
          <MenuList className={isMobile ? classes.horizontalMenu : ""}>
            {options.map((option, index) => (
              <MenuItem
                key={option.name}
                selected={index === selected}
                onClick={(e) => {
                  e.preventDefault()
                  setSelectedIndex(index)
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      ) : (
        <div className={classes.mobileMenu} style={{ alignContent: "center" }}>
          <Button
            className={classes.menuButton}
            variant={"contained"}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            Dashboard Menu
          </Button>
          <Popper
            id={"popper"}
            open={open}
            className={classes.popper}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.name}
                          selected={index === selected}
                          onClick={(e) => {
                            e.preventDefault()
                            handleClose(e)
                            setSelectedIndex(index)
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
    </div>
  )
}

export default DashboardMenu
