import React, { Component } from "react"
import moment from "moment"
import "./Message.css"
import { Common } from "../../../../helpers"

export default class Message extends Component {

  render() {
    const {
      data,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp,
      members,
    } = this.props

    const friendlyTimestamp = moment(data.timestamp).format("LLLL")
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`,
        ].join(" ")}
      >
        {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

        <div className="bubble-container">
          <div className="bubble" title={friendlyTimestamp}>
            {!isMine && <small className="other-author">{data.authorName}</small>}
            {Common.stripHtml(data.message)}
          </div>
        </div>
      </div>
    )
  }
}
