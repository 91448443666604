export const condition = ["New", "Pre-Owned", "Rental"]

export const electronics = [
  "Autopilot",
  "Bluetooth Audio",
  "CD Player",
  "Compass",
  "Depthsounder",
  "DVD Player",
  "GPS",
  "Premium Audio",
  "Radar",
  "Radio",
  "TV",
  "Satellite",
  "VHF",
]

export const engineBrands = [
  "Caterpillar",
  "Cox",
  "Cummins",
  "Evinrude",
  "Ford",
  "GM",
  "Honda",
  "MAN",
  "Mercury",
  "Mercruiser",
  "Suzuki",
  "Tohatsu",
  "Volvo",
  "Yamaha",
  "Yanmar",
  "7 Marine",
]

export const engine_manufacturers = [
  "Caterpillar",
  "Cox",
  "Cummins",
  "Evinrude",
  "Ford",
  "GM",
  "Honda",
  "MAN",
  "Mercury",
  "Mercruiser",
  "Suzuki",
  "Tohatsu",
  "Volvo",
  "Yamaha",
  "Yanmar",
  "7 Marine",
]

export const engine_type = ["Inboard", "Outboard"]

export const features = [
  "Second Station",
  "Live Well",
  "Electric Bilge Pump",
  "Manual Bilge Pump",
  "Sea Water Pump",
  "Stern Thruster",
  "Bow Thruster",
  "Battery Charger",
  "Shore Power Inlet",
  "Inverter",
  "Generator",
  "Refrigerator",
  "Freezer",
  "Fresh Water Maker",
  "Hot Water",
  "Dishwasher",
  "Washing Machine",
  "Air Compressor",
  "Air Conditioning",
  "Heating",
  "Oven",
  "Microwave Oven",
  "Head",
]

export const forSaleBy = ["Manufacturer", "Dealer", "Broker", "Owner"]

export const fuelType = ["Gas", "Diesel"]

export const hullType = ["Monohull", "Catamaran"]

export const manufacturers = [
  "Action Craft",
  "Admiral",
  "Airon",
  "Airship",
  "Al Dhaen",
  "Albemarle",
  "Albury Brothers",
  "Alen",
  "AmeraCat",
  "Angler",
  "Apreamare",
  "Aquasport",
  "Arth Boats",
  "Atlas Boatworks",
  "Avanti",
  "Avenger",
  "Axopar",
  "Bahama",
  "Baja",
  "Bayliner",
  "Belzona",
  "Beneteau",
  "Bertram",
  "Big Thunder",
  "Billfish",
  "Blackfin",
  "Blackjack",
  "Blackwater",
  "Blackwood",
  "Blazer",
  "Blue Wave",
  "Bluefin",
  "Bluewater",
  "Bluewater Sportfishing",
  "Boca Grande",
  "Bonadeo",
  "Boston Whaler",
  "Buddy Davis",
  "Bulls Bay",
  "C-Hawk",
  "Calyber",
  "Cape Craft",
  "Cape Horn",
  "Capelli",
  "Caravelle",
  "Carolina Skiff",
  "Carrera Boats",
  "Catamaran",
  "Caymas Boats",
  "Century",
  "Chaos",
  "Chris-Craft",
  "Cigarette",
  "Clearwater",
  "Cobalt",
  "Cobia",
  "Competition",
  "Concept",
  "Conch",
  "Contender",
  "Crevalle",
  "Crownline",
  "Custom",
  "Custom Carolina",
  "Dakota",
  "Davis",
  "Deep Impact",
  "Don Smith",
  "Donzi",
  "Dorado",
  "Drakkar Boats",
  "Dromeas Yachts",
  "Dusky",
  "Everglades",
  "Edgewater",
  "Eastern",
  "Epic",
  "Finnmaster",
  "Fish Hawk",
  "Fjord",
  "Formula",
  "Fountain",
  "Frauscher",
  "Freeman",
  "Frontier",
  "G3",
  "Gillikin",
  "Glacier Bay",
  "Glasstream",
  "Grady White",
  "Gulf Coast",
  "Gulfstream Yachts",
  "Hammer Yachts",
  "HCB",
  "Hells Bay",
  "Hewes",
  "Highfield",
  "Hinckley",
  "Hoog",
  "Hunton",
  "Hurricane",
  "Hysucat",
  "Insetta",
  "Intrepid",
  "Invincible",
  "Intruder",
  "Island Runner",
  "Invictus",
  "Jaguar",
  "Jarret Bay",
  "Jeanneau",
  "Jefferson",
  "Jersey Cape",
  "Jones Brothers",
  "Jupiter",
  "Karnic",
  "Key West",
  "Kencraft",
  "Key Largo",
  "Kelt",
  "King Cuda",
  "Laguna",
  "Latitude",
  "Legacy",
  "Lomac Ribs",
  "Mag Bay",
  "Majek",
  "Mako",
  "Mant",
  "Maori",
  "Marauder Marine",
  "Mares",
  "Marine Technology",
  "Maritime",
  "Marlago",
  "Marlin Yachts",
  "Marlow Prowler",
  "Maverick",
  "May Craft",
  "Mckinna",
  "Metan",
  "Midnight Express",
  "Mirage",
  "Mirage Manufacturing",
  "MTI",
  "Mystic Powerboats",
  "Nautica",
  "Nauticstar",
  "Neptune|",
  "Nor-Tech",
  "Nord Star",
  "Northcoast",
  "Novamarine",
  "Nuova Jolly",
  "OBX",
  "Ocean Express",
  "Ocean Master",
  "Ocean Runner",
  "Ocqueteau",
  "Pacific Craft",
  "Palm Beach",
  "Palmetto",
  "Panga",
  "Parker",
  "Pathfinder",
  "Pioneer",
  "Pirelli",
  "Polar",
  "Predator",
  "Pro Sports",
  "Pro-Line",
  "Prokat",
  "Proline",
  "Pronautica",
  "Protector",
  "Pursuit",
  "Quicksilver",
  "Rambo",
  "Rampage",
  "Rancraft",
  "Reef Runner",
  "Regal",
  "Regulator",
  "Release",
  "Renegade",
  "Revenge",
  "RH Boats",
  "Rhea|",
  "Riva",
  "Robalo",
  "Ros Boats",
  "Rose Bay",
  "Rossiter",
  "Roth Bilt",
  "Rybovich",
  "Sabalo",
  "Safe Boats",
  "Sailfish",
  "San Juan Yachts",
  "Scarab",
  "SCB",
  "Scorpion",
  "Scout Boats",
  "Sea Born",
  "Sea Boss",
  "Sea Cat",
  "Sea Chaser",
  "Sea Craft",
  "Sea Force LX",
  "Sea Fox",
  "Sea Hunt",
  "Sea Hunter",
  "Sea Lion Boats",
  "Sea Pro",
  "Sea Sport",
  "Sea Vee",
  "Sea Swirl",
  "Sealegs",
  "Seaway",
  "Selva",
  "Sessa",
  "Shallow Sport",
  "Shallow Stalker",
  "Shamrock",
  "Sheaffer Boats",
  "Shearwater",
  "Silverhawk",
  "Skeeter",
  "Solace",
  "Sonic",
  "Southern Skimmer",
  "Southport Boats",
  "Spectre",
  "Sportsman",
  "Statement",
  "Stoner Boatworks",
  "Stott Craft",
  "Streamline",
  "Technohull",
  "Tiara Sport",
  "Tiburon",
  "Tidewater",
  "Topaz",
  "Triton",
  "Triumph",
  "Trophy",
  "Turner Boatworks",
  "Twin Vee",
  "Venture",
  "Vandalize",
  "Vanquish",
  "V2",
  "Wahoo",
  "Walker Bay",
  "Wellcraft",
  "White Shark",
  "Whitewater",
  "Winter Custom Yachts",
  "Work Boat America",
  "World Cat",
  "Xcelerator Boatworks",
  "Yellowfin",
  "Yamaha Boats",
  "Zodiac",
]

export const trailer = [
  "No Trailer",
  "Single Axle",
  "Dual Axle",
  "Triple Axle",
  "Gooseneck",
]

export const vesselType = ["Bay", "Flats", "Hybrid", "OffShore"]
