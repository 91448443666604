import React, { Component } from "react"
import {
  isBrowser,
  getUuid,
  getEmail,
  renewToken,
  getAuthHeadersJsonapi,
} from "../../utils/Auth"
import { CloudinaryMediaSingle } from "../CloudinaryMedia"
import { RefreshContent } from "react-js-pull-to-refresh"
// @material-ui/core components
import Divider from "@material-ui/core/Divider"
import withStyles from "@material-ui/core/styles/withStyles"
// core components
import cardsStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx"
import { Grid, Paper } from "@material-ui/core"
import Upload from "../Upload"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"

const defaultAvatar = "defaults/default_avatar"
const baseUrl = `${process.env.GATSBY_API_URL}`
const params = "/jsonapi/user/user/"

const style = {
  ...cardsStyle,
  noBoxShadow: {
    boxShadow: "none",
  },
  cardPadding: {
    padding: "0px !important",
  },
  cardText: {
    textAlign: "center",
    padding: "0px !important",
  },
  xButtonDiv: {
    width: "100%",
  },
  xButton: {
    float: "right",
    background: "#ffffff",
    border: "2px solid rgb(0, 172, 193)",
    marginTop: "12px",
  },
  paper: {
    padding: "20px 0 30px 0",
    margin: "20px 0",
  },
}

class Profile extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      avatar: "",
      date: "",
      upload: false,
      error: "",
      loading: false,
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.getUserProfile()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getUserProfile() {
    if (!isBrowser) {
      return
    }

    let self = this
    self._isMounted &&
      self.setState({
        loading: true,
      })

    axios
      .get(baseUrl + params + getUuid(), getAuthHeadersJsonapi())
      .then(function (response) {
        const data = response.data.data
        let userDate = new Date(data.attributes.created)
        let avatar = data.attributes.field_user_avatar
          ? data.attributes.field_user_avatar
          : defaultAvatar
        self._isMounted &&
          self.setState({
            name: data.attributes.name,
            firstName: data.attributes.field_first_name
              ? data.attributes.field_first_name
              : "",
            lastName: data.attributes.field_last_name
              ? data.attributes.field_last_name
              : "",
            email: getEmail(),
            avatar: avatar,
            date: userDate.toDateString(),
            loading: false,
          })
      })
      .catch(function (error) {
        console.log(error.response.status)
        // const status = error.response.status
        // status === 401 && renewToken()
        // isAuthenticated && self.getUserProfile()
        self._isMounted &&
          self.setState({
            loading: false,
          })
      })
  }

  updateProfile = (r) => {
    // console.log(response)
    let self = this
    const avatar = r.data.public_id

    const payload = {
      data: {
        type: "user--user",
        id: getUuid(),
        attributes: {
          field_user_avatar: avatar,
        },
      },
    }

    axios
      .patch(baseUrl + params + getUuid(), payload, getAuthHeadersJsonapi())
      .then(function (response) {
        // console.log(response);
        self._isMounted &&
          self.setState(
            {
              avatar: avatar,
              upload: false,
            },
            renewToken()
          )
      })
      .catch(function (error) {
        console.log(error.response)
      })
  }

  updateAvatar = () => {
    const { upload } = this.state
    return upload ? (
      <>
        <div style={style.xButtonDiv}>
          <button
            onClick={(e) => {
              e.preventDefault()
              this._isMounted && this.setState({ upload: !upload })
            }}
            style={style.xButton}
            type={"submit"}
          >
            <FontAwesomeIcon icon={"times"} color={"rgb(0, 172, 193)"} />
          </button>
        </div>
        <Upload limit={1} callback={this.updateProfile} />
      </>
    ) : (
      <a
        href={"#"}
        onClick={(e) => {
          e.preventDefault()
          this.setState({ upload: true })
        }}
      >
        <p>Update Avatar</p>
      </a>
    )
  }

  render() {
    const { classes } = this.props
    const {
      name,
      avatar,
      date,
      email,
      firstName,
      lastName,
      loading,
    } = this.state
    const fieldImage = { pid: avatar, resourceType: "image" }

    return (
      <Paper style={style.paper}>
        <GridContainer
          spacing={6}
          direction="row"
          alignItems="center"
          justify="center"
        >
          {loading ? (
            <RefreshContent />
          ) : (
            <>
              <GridItem md={5} xs={12}>
                {avatar && (
                  <CloudinaryMediaSingle
                    image={fieldImage}
                    transformations={"q_auto,f_auto,dpr_auto,w_480,c_scale"}
                    alt={name}
                    progressive={"w_480,c_scale"}
                  />
                )}
              </GridItem>
              <GridItem md={5} xs={12} style={style.cardText}>
                <h4 className={classes.cardTitle}>{name}</h4>
                <h5
                  className={`${classes.cardCategory} ${classes.cardDescription}`}
                >
                  {firstName + " " + lastName}
                </h5>
                <h5
                  className={`${classes.cardCategory} ${classes.cardDescription}`}
                >
                  {email}
                </h5>
                <h6
                  className={`${classes.cardCategory} ${classes.cardDescription}`}
                >
                  Member Since: {date}
                </h6>
                <Divider style={{ margin: "12px 0" }} />
                {this.updateAvatar()}
              </GridItem>
            </>
          )}
        </GridContainer>
      </Paper>
    )
  }
}

export default withStyles(style)(Profile)
