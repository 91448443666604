import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

export default function Message(props) {
  const { open, success, update } = props
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        // onClose={success}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Congratulations!!"}
        </DialogTitle>
        <DialogContent>
          {update ? (
            <DialogContentText>
              Hooray, your listing has been updated and will be updated
              completely in our system within the hour. You can see the changes
              have been made by clicking edit again on the updated listing.
              Thank you again and good luck selling your boat!
            </DialogContentText>
          ) : (
            <DialogContentText>
              Hooray, your listing has been received and will be reviewed for
              quality assurance before going live! This usually takes less than
              an hour during normal business hours! Thank you again and good
              luck selling your boat!
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={success} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
