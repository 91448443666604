import React from "react"
import { Router } from "@reach/router"
// Layout/Routing Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import PrivateRoute from "../components/PrivateRoute"
// Routable Components
import Post from "../components/Post"
import Login from "../components/Login"
import Dashboard from "../components/Dashboard"
import PrivateMessages from "../components/PrivateMessages"
import PostBoatListing from "../components/PostBoatListing"
import EditBoatListing from "../components/PostBoatListing/edit"
import RenewListing from "../components/RenewListing"

const UserPage = ({ location: { pathname, state } }) => {
  return (
    <Layout>
      <SEO title="CCO User Console" />
      <Router>
        <PrivateRoute path="/u/dashboard" component={Dashboard} />
        <PrivateRoute path="/u/messages" component={PrivateMessages} />
        <PrivateRoute path="/u/post/boat" component={PostBoatListing} />
        <PrivateRoute path="/u/post/boat/:id/edit" component={EditBoatListing} />
        <PrivateRoute path="/u/post/community" component={Post} />
        <PrivateRoute path="/u/renew_listing" component={RenewListing} />
        <Login path="/login" />
      </Router>
    </Layout>
  )
}

export default UserPage
