import React, { Component } from "react"
import axios from "axios"
import { getAccessToken, getUuid } from "../../utils/Auth"
import { List } from "react-content-loader"
import ModalSuccess from "../ModalSuccess"
// Braintree Components
import PaymentMethods from "./paymentMethods"
import Customer from "./customer"
import Subscriptions from "./subscriptions"
// Material UI
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import { emailIsValid, scrollToTop, urlIsValid } from "../../helpers/common"

const style = {
  gridItems: {
    padding: "0 30px 30px",
  },
}

const contactFields = [
  {
    label: "First Name",
    required: true,
    name: "firstName",
  },
  {
    label: "Last Name",
    required: true,
    name: "lastName",
  },
  {
    label: "Company",
    required: true,
    name: "company",
  },
  {
    label: "Phone Number",
    required: true,
    type: "tel",
    name: "phone",
  },
  {
    label: "Website URL",
    required: true,
    type: "url",
    name: "website",
    pattern: 'pattern="https?://.+"',
    fullWidth: true,
  },
]

const billingFields = [
  {
    label: "First Name",
    required: true,
    name: "bFirstName",
    handler: true,
  },
  {
    label: "Last Name",
    required: true,
    name: "bLastName",
    handler: true,
  },
  {
    label: "Company",
    required: true,
    name: "bCompany",
    handler: true,
  },
  {
    label: "Street Address",
    required: true,
    name: "bStreetAddress",
    handler: true,
  },
  {
    label: "City",
    required: true,
    name: "bLocality",
    handler: true,
  },
  {
    label: "State / Region",
    required: true,
    name: "bRegion",
    handler: true,
  },
  {
    label: "Postal Code",
    required: true,
    name: "bPostalCode",
    handler: true,
  },
]

class Braintree extends Component {
  _isMounted = false
  state = {
    customer: null,
    clientToken: null,
    nonce: null,
    paymentError: null,
    loading: true,
    // Form Inputs
    firstName: "",
    lastName: "",
    company: "",
    phone: "",
    website: "",
    billingAddress: {
      bFirstName: "",
      bLastName: "",
      bCompany: "",
      bStreetAddress: "",
      bLocality: "",
      bRegion: "",
      bPostalCode: "",
    },
    copy: false,
    selectedSubscription: null,
    fieldErrors: {},
    currentSubscriptions: [],
    success: false,
  }

  componentDidMount() {
    this._isMounted = true
    this.getCustomer()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleChange = (event) => {
    const key = event.target.name
    const value = event.target.value
    const isValid = this.validate(key, value)

    this._isMounted &&
      this.setState({
        fieldErrors: {
          ...this.state.fieldErrors,
          [key]: isValid,
        },
        [key]: value,
      })
  }

  handleChangeBilling = (event) => {
    const key = event.target.name
    const value = event.target.value
    const isValid = this.validate(key, value)

    this.setState({
      fieldErrors: {
        ...this.state.fieldErrors,
        [key]: isValid,
      },
      billingAddress: {
        ...this.state.billingAddress,
        [key]: value,
      },
    })
  }

  handleNonce = (nonce) => {
    this._isMounted && this.setState({ nonce: nonce, paymentError: null })
  }

  handlePaymentErrors = (error) => {
    this._isMounted && this.setState({ paymentError: error })
  }

  handleClearErrors = (error) => {
    this._isMounted && this.setState({ paymentError: null })
  }

  handleClearSelectedPayment = () => {
    this._isMounted && this.setState({ paymentError: null, nonce: null })
  }

  handleSelectedSubscription = (plan) => {
    const { selectedSubscription } = this.state
    const selected = selectedSubscription === plan ? null : plan
    this._isMounted && this.setState({ selectedSubscription: selected })
  }

  handleSuccessUpdate = () => {
    scrollToTop()
    this._isMounted && this.setState({ success: false })
  }

  handleFormErrors = (field, outcome) => {
    this._isMounted &&
      this.setState({
        fieldErrors: { ...this.state.fieldErrors, [`${field}`]: outcome },
      })
  }

  copyToBilling = () => {
    const { firstName, lastName, company } = this.state
    this._isMounted &&
      this.setState({
        billingAddress: {
          ...this.state.billingAddress,
          bFirstName: firstName,
          bLastName: lastName,
          bCompany: company,
        },
      })
  }

  validate = (key, value) => {
    if (value.length > 0) {
      if (key === "website" && !urlIsValid(value)) return true
      if (key === "email" && !emailIsValid(value)) return true
      if (key === "phone" && (!/^\d+$/.test(value) || value.length !== 10))
        return true
      if (value.length < 2) return true
    }
    return false
  }

  getCustomer() {
    let self = this
    axios
      .post("/.netlify/functions/bt-get-customer", {
        token: getAccessToken(),
        uuid: getUuid(),
      })
      .then(function (response) {
        // console.log(response)
        const { data } = response
        if (data.customer != null && self._isMounted) {
          self.setState(
            {
              customer: data.customer,
              loading: false,
            },
            () => self.getToken(self.state.customer.id)
          )
        } else {
          self._isMounted &&
            self.setState(
              {
                loading: false,
              },
              () => self.getToken()
            )
        }
      })
      .catch(function (err) {
        console.log(err.response)
      })
  }

  // Get a client token for authorization from your server
  async getToken(id) {
    let self = this
    axios
      .post("/.netlify/functions/bt-get-token", {
        customerId: id,
      })
      .then(function (response) {
        // console.log(response)
        self._isMounted &&
          self.setState({
            clientToken: response.data.token,
          })
      })
  }

  createCustomer = () => {
    let self = this
    const {
      customer,
      nonce,
      firstName,
      lastName,
      company,
      phone,
      website,
      billingAddress,
      selectedSubscription,
      loading,
    } = self.state
    // console.log(self.state)

    axios
      .post("/.netlify/functions/bt-create-subscription", {
        nonceFromTheClient: nonce,
        token: getAccessToken(),
        uuid: getUuid(),
        customer: customer,
        firstName: firstName,
        lastName: lastName,
        company: company,
        phone: phone,
        website: website,
        billingAddress: billingAddress,
        selectedSubscription: selectedSubscription,
      })
      .then(function (response) {
        console.log(response)
        const { data } = response
        if (data.customer != null || data.subscription != null) {
          self._isMounted &&
            self.setState({ loading: true, success: true }, () =>
              self.getCustomer()
            )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  disableSubmit = () => {
    const {
      nonce,
      customer,
      selectedSubscription,
      fieldErrors,
      billingAddress,
    } = this.state
    return (
      Object.values(fieldErrors).includes(true) ||
      !nonce ||
      !selectedSubscription ||
      (!customer && Object.values(billingAddress).includes(""))
    )
  }

  showPaymentMethods = () => {
    let count = 0
    const { customer } = this.state
    if (customer && customer.paymentMethods.length > 0) {
      customer.paymentMethods.map((e) => {
        if (e.subscriptions.length > 0) {
          e.subscriptions.map((s) => {
            count = count + 1
          })
        }
      })
    }
    return count > 0
  }

  render() {
    // console.log(this.state)
    const {
      loading,
      customer,
      paymentError,
      selectedSubscription,
      success,
    } = this.state
    return (
      <GridContainer spacing={0} alignContent={"center"}>
        <GridItem xs={12} style={style.gridItems}>
          <h2>My Subscriptions</h2>
          {loading ? (
            <List height={"200px"} />
          ) : (
            <Subscriptions
              key={selectedSubscription}
              customer={customer}
              handleSelectedSubscription={this.handleSelectedSubscription}
              selectedSubscription={selectedSubscription}
              // currentSubscriptions={currentSubscriptions}
              // handleCurrentSubscriptions={this.handleCurrentSubscriptions()}
            />
          )}
        </GridItem>
        <GridItem xs={12} style={style.gridItems}>
          {loading ? (
            <List style={{ paddingTop: "30px" }} height={"200px"} />
          ) : (
            <Customer
              customer={customer}
              handleChangeBilling={this.handleChangeBilling}
              handleChange={this.handleChange}
              values={this.state}
              copyToBilling={this.copyToBilling}
              contactFields={contactFields}
              billingFields={billingFields}
              handleFormErrors={this.handleFormErrors}
            />
          )}
        </GridItem>
        <GridItem xs={12} style={{ padding: "30px" }}>
          <h2>My Payment Methods</h2>
          {loading ? (
            <List height={"200px"} />
          ) : (
            <PaymentMethods
              clientToken={this.state.clientToken}
              nonce={this.state.nonce}
              handleNonce={this.handleNonce}
              error={paymentError}
              handlePaymentErrors={this.handlePaymentErrors}
              handleClearErrors={this.handleClearErrors}
              handleClearSelectedPayment={this.handleClearSelectedPayment}
              createCustomer={this.createCustomer}
              disableSubmit={this.disableSubmit || this.showPaymentMethods}
            />
          )}
        </GridItem>
        {success && (
          <ModalSuccess
            success={success}
            update={this.handleSuccessUpdate}
            message={"Your Subscription has been purchased! Welcome aboard!"}
            subMessage={
              "NOTE: It may take up to 1 hr to completely update on our system."
            }
          />
        )}
      </GridContainer>
    )
  }
}

export default Braintree
