import React, { Component } from "react"
import ConversationListItem from "../ConversationListItem"
import Toolbar from "../Toolbar"
import axios from "axios"
import { joinArrays } from "../../../../helpers/common"
import {
  isBrowser,
  getAuthHeadersJsonapi,
  getUuid,
} from "../../../../utils/Auth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RefreshContent } from "react-js-pull-to-refresh"
import "./ConversationList.css"

const baseUrl = `${process.env.GATSBY_API_URL}`

// Merge included data from query into data array
const mergeConversationArrays = (arr, inc) => {
  let rows = []
  for (let i = 0; i < arr.length; i++) {
    arr[i].relationships.members.data = joinArrays(
      inc,
      arr[i].relationships.members.data,
      "id",
      "id",
      function(user, details) {
        return {
          id: user.id,
          name: details.attributes.name,
          avatar: details.attributes.field_user_avatar,
        }
      }
    )
    rows[i] = arr[i]
  }
  // console.log(threadRows);
  return rows
}

export default class ConversationList extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      conversations: [],
      threadList: [],
      loading: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { conversation, refresh, refreshConversations } = this.props
    if (conversation !== prevProps.conversation && conversation) {
      this.getConversations()
    }
    if (refreshConversations) {
      refresh("c")
      this.getConversations()
    } else if (refreshConversations && conversation) {
      refresh("c")
      this.getConversations()
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.setState({ loading: true }, () => this.getConversations())
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getConversations = () => {
    if (!isBrowser) {
      return
    }

    let self = this
    const endpoint = "/jsonapi/private_message_thread/private_message_thread"
    // const params = "?include=members&sort=-updated"
    const params = `?filter[members][condition][path]=members.id&filter[members][condition][operator]=IN&filter[members][condition][value][]=${getUuid()}&include=members`
    axios
      .get(baseUrl + endpoint + params, getAuthHeadersJsonapi())
      .then(response => {
        if (response.data.data.length !== 0 && self._isMounted) {
          self.setState(prevState => {
            let conversations = mergeConversationArrays(
              response.data.data,
              response.data.included
            ).map(result => {
              return {
                id: result.id,
                details: result.relationships.members.data,
                updated: result.attributes.updated,
              }
            })
            return { ...prevState, conversations, loading: false }
          })
        } else {
          self._isMounted && self.setState({ loading: false })
        }
      })
      .catch(function(error) {
        // console.log(error)
      })
  }

  updateId = (id, members, messages) => {
    const {
      isMobile,
      memberCallback,
      conversationCallback,
      messagesCallback,
      handleShowConversations,
    } = this.props

    memberCallback(members)
    conversationCallback(id)
    !messages && messagesCallback([])
    isMobile && handleShowConversations(false)
  }

  render() {
    const { loading, conversations } = this.state
    const { conversation } = this.props
    const sortedThreads = conversations.sort(
      (a, b) => Date.parse(b.updated) - Date.parse(a.updated)
    )
    return (
      <div className="conversation-list">
        <Toolbar
          title={"Conversations"}
          leftItems={[
            <button
              key={"refresh"}
              onClick={() =>
                this.setState({ loading: true }, this.getConversations())
              }
              className="clear-button icon-button"
              type={"submit"}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={"sync"} color={"#00acc1"} size={"2x"} />
            </button>,
          ]}
          rightItems={[
            <button
              key={"add"}
              onClick={() => this.updateId(null, [], false)}
              className="clear-button"
              type={"submit"}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon
                icon={"plus-circle"}
                color={"#00acc1"}
                size={"2x"}
              />
            </button>,
          ]}
        />
        {loading && <RefreshContent />}
        {sortedThreads.map((thread, idx) => (
          <button
            key={idx}
            onClick={() => this.updateId(thread.id, thread.details, false)}
            className="clear-button"
          >
            <ConversationListItem
              key={idx}
              data={thread}
              active={conversation}
            />
          </button>
        ))}
      </div>
    )
  }
}
