import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import useMediaQuery from "@material-ui/core/useMediaQuery"
// @material-ui/icons
import Close from "@material-ui/icons/Close"
// core components
import Button from "@material-ui/core/Button"
// Styling
import modalStyle from "../../assets/jss/material-kit-pro-react/modalStyle"
import useTheme from "@material-ui/core/styles/useTheme"

const style = theme => ({
  ...modalStyle(theme),
  modalRootLocal: {
    "& > div:first-child": {
      display: "none",
    },
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
})

const useStyles = makeStyles(style)

const Modal = props => {
  const {
    data,
    open,
    handleModal,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div>
      {open && (
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          onClose={() => handleModal(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple={"true"}
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => handleModal(false)}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            {data}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            {/*<Button onClick={() => handleModal(false)}>Close</Button>*/}
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default Modal
