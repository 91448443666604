import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import React from "react"

function CustomSelectInput(props) {
  const {
    required = true,
    options,
    name,
    classes,
    handleChange,
    value,
    title,
    multiple = false,
  } = props

  return (
    <FormControl
      required={required}
      fullWidth
      className={classes.selectFormControl}
      style={{ padding: "13px 0" }}
    >
      <InputLabel htmlFor={name} className={classes.selectLabel}>
        {title}
      </InputLabel>
      <Select
        multiple={multiple}
        MenuProps={{ className: classes.selectMenu }}
        classes={{ select: classes.select }}
        value={value}
        onChange={handleChange}
        inputProps={{
          name: name,
          id: name,
        }}
      >
        <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
          {title}
        </MenuItem>
        {options}
      </Select>
    </FormControl>
  )
}

export default CustomSelectInput
