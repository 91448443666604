import React, { Component } from "react"
import axios from "axios"
import { getUuid, isBrowser, getAuthHeadersJsonapi } from "../../utils/Auth"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import PostCard from "../PostCard"
import Paper from "@material-ui/core/Paper"
import { RefreshContent } from "react-js-pull-to-refresh"

const baseUrl = `${process.env.GATSBY_API_URL}`

const style = {
  root: {
    display: "flex",
  },
  paper: {
    marginTop: "20px",
    width: "100%",
    marginBottom: "30px",
  },
  horizontalMenu: {
    display: "inline-flex",
  },
  noPosts: {
    padding: "65px",
    minHeight: "200px",
    lineHeight: "100px",
    textAlign: "center",
  },
}

class UserPosts extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      posts: [],
      loading: false,
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.getUserPosts()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getUserPosts() {
    if (!isBrowser) return

    let self = this
    const params =
      "/jsonapi/node/community?filter[status][value]=1&filter[uid.id][value]="

    self._isMounted &&
      self.setState({
        loading: true,
      })

    axios
      .get(baseUrl + params + getUuid(), getAuthHeadersJsonapi())
      .then(function(response) {
        const data =
          response.data.data.length > 0 ? response.data.data.reverse() : []
        self._isMounted &&
          self.setState({
            posts: data,
            loading: false,
          })
      })
      .catch(function(error) {
        // console.log(error)
        self._isMounted &&
          self.setState({
            loading: false,
          })
      })
  }

  unpublishUserPost = postId => {
    let self = this
    const params = "/jsonapi/node/community" + "/" + postId

    const payload = {
      data: {
        type: "node--community",
        id: postId,
        attributes: {
          status: 0,
        },
      },
    }

    axios
      .patch(baseUrl + params, payload, getAuthHeadersJsonapi())
      .then(function(response) {
        // console.log(response)
        self._isMounted &&
          self.setState(prevState => ({
            posts: prevState.posts.filter(item => item.id !== postId),
          }))
      })
      .catch(function(error) {
        console.log(error.response)
      })
  }

  render() {
    const { posts, loading } = this.state
    return (
      <GridContainer
        spacing={3}
        direction="row"
        alignItems="center"
        justify="center"
      >
        <GridItem>
          {posts.length > 0 ? (
            posts.map((post, idx) => (
              <PostCard
                key={idx}
                id={post.id}
                title={post.attributes.title}
                created={post.attributes.created}
                alias={post.attributes.path.alias}
                deleteItem={this.unpublishUserPost}
                likes={post.attributes.field_like.likes}
                commentCount={post.attributes.comment.comment_count}
                fieldImage={JSON.parse(post.attributes.field_cloudinary)}
                message={post.attributes.body.value}
                type={post.type}
              />
            ))
          ) : (
            <div style={style.root}>
              <Paper style={style.paper}>
                <div style={style.noPosts}>
                  {loading ? (
                    <RefreshContent />
                  ) : (
                    <h4>
                      You have not posted to the community yet. Get busy!! ;)
                    </h4>
                  )}
                </div>
              </Paper>
            </div>
          )}
        </GridItem>
      </GridContainer>
    )
  }
}

export default UserPosts
