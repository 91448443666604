import axios from "axios"

const base64ImageToBlob = (str, name) => {
  // extract content type and base64 payload from original string
  const pos = str.indexOf(";base64,")
  const type = str.substring(5, pos)
  const b64 = str.substr(pos + 8)

  // decode base64
  const imageContent = atob(b64)

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  const buffer = new ArrayBuffer(imageContent.length)
  const view = new Uint8Array(buffer)

  // fill the view, using the decoded base64
  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n)
  }

  // convert ArrayBuffer to Blob
  const blob = new Blob([buffer], { type: type })

  return new File([blob], name, {type: type})
}

const getRemoteFiles = async url => {
  let image
  try {
    const result = await axios.post("/.netlify/functions/return-image", {
      url: url,
    })
    // console.log(result.data.image)
    image = await base64ImageToBlob(result.data.image, result.data.name)
  } catch (error) {
    console.log("error", error)
    return
  }

  return image
}

export default getRemoteFiles