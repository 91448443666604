import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { isAuthenticated } from "../../utils/Auth"
import { withStoreContext } from "../../context/StoreContext"

const isBrowser = typeof window !== "undefined"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isBrowser) return

  const { pathname, search } = location

  if (!isAuthenticated() && pathname !== `/login`) {
    // Redirect with location search
    navigate(`/login`, {
      state: { redirect: search != null ? pathname + search : pathname },
    })
    return null
  }

  if (
    pathname === `/u/dashboard` &&
    rest.storeContext.checkout.id === undefined
  ) {
    rest.storeContext.initiateStore()
  }

  return <Component {...rest} location={location} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default withStoreContext(PrivateRoute)
