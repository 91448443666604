import React, { Component } from "react"
import axios from "axios"
import { getUuid, isBrowser, getAuthHeadersJsonapi } from "../../utils/Auth"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import PostCard from "../PostCard"
import Paper from "@material-ui/core/Paper"
import { RefreshContent } from "react-js-pull-to-refresh"

const baseUrl = `${process.env.GATSBY_API_URL}`

const style = {
  root: {
    display: "flex",
  },
  paper: {
    marginTop: "20px",
    width: "100%",
    marginBottom: "30px",
  },
  horizontalMenu: {
    display: "inline-flex",
  },
  noListings: {
    padding: "65px",
    minHeight: "200px",
    lineHeight: "100px",
    textAlign: "center",
  },
}

class UserListings extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      listings: [],
      loading: false,
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.getUserListings()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getUserListings() {
    if (!isBrowser) return

    let self = this
    const params =
      "/jsonapi/node/listing?filter[status][value]=1&filter[uid.id][value]="

    self._isMounted &&
      self.setState({
        loading: true,
      })

    axios
      .get(baseUrl + params + getUuid(), getAuthHeadersJsonapi())
      .then(function (response) {
        const data =
          response.data.data.length > 0 ? response.data.data.reverse() : []
        self._isMounted &&
          self.setState({
            listings: data,
            loading: false,
          })
      })
      .catch(function (error) {
        // console.log(error)
        self._isMounted &&
          self.setState({
            loading: false,
          })
      })
  }

  unpublishUserListing = (listingId) => {
    let self = this
    const params = "/jsonapi/node/listing" + "/" + listingId

    const payload = {
      data: {
        type: "node--listing",
        id: listingId,
        attributes: {
          status: 0,
        },
      },
    }

    axios
      .patch(baseUrl + params, payload, getAuthHeadersJsonapi())
      .then(function (response) {
        // console.log(response)
        self._isMounted &&
          self.setState((prevState) => ({
            listings: prevState.listings.filter(
              (item) => item.id !== listingId
            ),
          }))
      })
      .catch(function (error) {
        console.log(error.response)
      })
  }

  render() {
    const { listings, loading } = this.state
    const {
      storeContext: { addVariantToCart },
    } = this.props
    return (
      <GridContainer
        spacing={3}
        direction="row"
        alignItems="center"
        justify="center"
      >
        {loading ? (
          <RefreshContent />
        ) : (
          <GridItem>
            {listings.length > 0 ? (
              listings.map((post, idx) => (
                <PostCard
                  key={idx}
                  id={post.id}
                  title={post.attributes.title}
                  created={post.attributes.created}
                  alias={post.attributes.path.alias}
                  deleteItem={this.unpublishUserListing}
                  likes={post.attributes.field_like.likes}
                  commentCount={post.attributes.comment.comment_count}
                  fieldImage={JSON.parse(post.attributes.field_cloudinary)}
                  data={post}
                  type={post.type}
                  addVariantToCart={addVariantToCart}
                />
              ))
            ) : (
              <div style={style.root}>
                <Paper style={style.paper}>
                  <div style={style.noListings}>
                    <h4> You do not have any listings.</h4>
                  </div>
                </Paper>
              </div>
            )}
          </GridItem>
        )}
      </GridContainer>
    )
  }
}

export default UserListings
