import React, { Component } from "react"
import Button from "../../../CustomButtons/Button"

export default class Suggestions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      members: [],
    }
  }

  render() {
    const { results, addMember } = this.props
    return (
      <div>
        {this.state.members.map((users, idx) => (
          <div key={idx}>
            <p>
              {users.name} {users.id}
            </p>
          </div>
        ))}
        <ul>
          {results.map((r, idx) => (
            <Button
              key={idx}
              onClick={() => addMember(r.id, r.name)}
              // className="clear-button"
              type={"submit"}
            >
              <li>{r.name}</li>
            </Button>
          ))}
        </ul>
      </div>
    )
  }
}
