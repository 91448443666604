import React, { useState } from "react"
import { CloudinaryMediaSingle } from "../CloudinaryMedia"
import Shaka from "../../components/Likes/Shaka/"
import TimeAgo from "react-timeago/lib"
import { existingUrls } from "../../hooks/existingUrls"
import SweetAlert from "react-bootstrap-sweetalert"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui icons
import ChatBubble from "@material-ui/icons/ChatBubble"
// core components
import Danger from "../Typography/Danger"
import Warning from "../Typography/Warning"
import Divider from "@material-ui/core/Divider"
import cardsStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/sectionCards"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import { Paper } from "@material-ui/core"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import EditPost from "../Post/edit"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tooltip from "@material-ui/core/Tooltip"

const boostVariantId =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjEwMTkxMjYwODg2Nw=="

const style = {
  ...cardsStyle,
  author: {
    float: "left",
  },
  paper: {
    padding: "20px 20px 30px 20px",
    margin: "20px 0",
  },
  card: {
    padding: "12px",
    margin: "20px 0",
    width: "100%",
  },
  danger: {
    float: "right",
  },
  shaka: {
    height: "20px",
    paddingRight: "4px",
  },
  cardImage: {
    borderRadius: "6px",
    overflow: "hidden",
    width: "100%",
  },
}

const useStyles = makeStyles(style)

const checkExistingUrls = (alias) => {
  const urls = existingUrls()
  for (let i = 0; i < urls.length; i++) {
    if (alias === urls[i].node.path) {
      return alias
    }
  }
  return `/feed${alias}`
}

const PostCard = (props) => {
  const {
    id,
    fieldImage,
    title,
    likes,
    commentCount,
    created,
    alias,
    deleteItem,
    message,
    type,
    data,
    addVariantToCart,
  } = props
  const classes = useStyles()
  const [showAlert, setShowAlert] = useState(false)
  const customAttributes = [
    {
      key: "Boat",
      value: title,
    },
    {
      key: "Listing URL",
      value: `https://centerconsolesonly.net${alias}`,
    },
  ]

  const handleAddVariantToCart = (e) => {
    e.preventDefault()
    addVariantToCart(boostVariantId, 1, customAttributes)
  }

  return (
    <Paper className={classes.paper}>
      <GridContainer spacing={3} alignItems="center" justify="center">
        <GridItem md={3} xs={12}>
          <CloudinaryMediaSingle
            image={fieldImage[0]}
            transformations={"h_236,c_scale"}
            alt={title}
            styles={style.cardImage}
          />
        </GridItem>
        <GridItem md={8} xs={12}>
          {type === "node--listing" && (
            <Tooltip
              placement="top"
              title="Boost this listing on our Instagram and Facebook Channels"
            >
              <Button
                style={{
                  backgroundColor: "red",
                  color: "white",
                  fontWeight: "bold",
                }}
                onClick={(e) => handleAddVariantToCart(e)}
              >
                Boost My Listing Now!
              </Button>
            </Tooltip>
          )}
          <Link
            to={type === "node--community" ? checkExistingUrls(alias) : alias}
          >
            <h4 className={classes.cardTitle}>{title}</h4>
          </Link>
          <Divider style={{ margin: "10px 0" }} />
          <div
            style={{ float: "left" }}
            className={`${classes.stats} ${classes.mlAuto}`}
          >
            <TimeAgo date={created} />
          </div>
          <div
            style={{ float: "right" }}
            className={`${classes.stats} ${classes.mlAuto}`}
          >
            <Shaka shaka styles={style.shaka} />
            {likes} ·
            <ChatBubble htmlColor={"#5987a1"} />
            {commentCount}
          </div>
        </GridItem>
        <GridItem style={{ textAlign: "center" }} md={1} xs={12}>
          {type === "node--community" && (
            <Warning>
              <EditPost
                id={id}
                message={message}
                fieldImage={fieldImage}
                icon={["fas", "edit"]}
              />
            </Warning>
          )}
          {type === "node--listing" && (
            <Warning>
              <Link to={`/u/post/boat/${id}/edit`} state={{ data: data }}>
                <Button>
                  <FontAwesomeIcon icon={["fas", "edit"]} />
                </Button>
              </Link>
            </Warning>
          )}
          <Danger>
            <Button onClick={() => setShowAlert(true)}>X</Button>
          </Danger>
          {showAlert && (
            <SweetAlert
              danger
              showCancel
              title="Are you sure??"
              customButtons={
                <React.Fragment>
                  <Button
                    onClick={() => {
                      setShowAlert(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      deleteItem(id)
                      setShowAlert(false)
                    }}
                  >
                    Yes, delete it!
                  </Button>
                </React.Fragment>
              }
              onConfirm={() => {
                deleteItem(id)
                setShowAlert(false)
              }}
              onCancel={() => {
                setShowAlert(false)
              }}
            >
              If you confirm it will be gone forever!
              <br />
              <small>
                NOTE: It may take up to 1 hr to completely remove from our
                system.
              </small>
            </SweetAlert>
          )}
        </GridItem>
      </GridContainer>
    </Paper>
  )
}

export default PostCard
