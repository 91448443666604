import React, { Component } from "react"
import axios from "axios"
import arrayMove from "array-move"
import SortableImages from "../SortableImages"
import { getAuthHeadersJsonapi } from "../../utils/Auth"
import {
  truncateString,
  searchArray,
  scrollToTop,
  timeToMillis,
  generateTimeSeries,
} from "../../helpers/common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// Material UI & Kit
import Clearfix from "../Clearfix/Clearfix"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import Paper from "@material-ui/core/Paper"
import Button from "../CustomButtons/Button"
import Upload from "../Upload"
import Modal from "./uploadModal"
import BaseFields from "./baseFields"
import ListFields from "./listFields"
import Hours from "./hours"
import ModalSuccess from "../ModalSuccess"
import { Link } from "gatsby"
import DirectoryLink from "./directoryLink"

const baseUrl = `${process.env.GATSBY_API_URL}`

const hourOptions = generateTimeSeries(15)

const style = {
  paper: {
    margin: "20px 0 20px 0",
    padding: "20px",
  },
  buttons: {
    paddingBottom: "26px",
  },
}

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

const INITIAL_HOURS = days.map((d) => {
  return {
    day: d,
    status: "open",
    open: "8:00 AM",
    close: "5:00 PM",
    timezone: "EST",
  }
})

class DirectoryForm extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      id: "",
      name: "",
      contact: "",
      phone: "",
      email: "",
      address: "",
      product: "",
      products: [],
      service: "",
      services: [],
      url: "",
      description: "",
      summary: "",
      images: [],
      hours: INITIAL_HOURS,
      success: false,
      uploadComplete: false,
      addImages: false,
      hourErrors: [null, null, null, null, null, null, null],
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { action } = this.props
    this._isMounted = true
    action === "edit" && this.getInitialState()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleChange = (event) => {
    const key = event.target.name
    const value = event.target.value

    this._isMounted &&
      this.setState({
        [key]: value,
      })
  }

  handleChangeHours = (e, idx) => {
    const { hours, hourErrors } = this.state
    const key = e.target.name
    const value = e.target.value
    const eMessage = "Close must be at least an hour later than open"

    if (key === "close" || key === "open") {
      const isCloseLaterThanOpen = () =>
        timeToMillis(key === "close" ? value : hours[idx].close) -
          timeToMillis(key === "open" ? value : hours[idx].open) >=
        3600000

      const array = [...hourErrors]
      array.splice(idx, 1, !isCloseLaterThanOpen() ? eMessage : null)
      this._isMounted &&
        this.setState({
          hourErrors: array,
        })
    }

    this._isMounted &&
      this.setState({
        hours: hours.map((el, i) =>
          i === idx ? { ...el, [`${key}`]: value } : el
        ),
      })
  }

  handleListAdd = (list, current) => {
    if (
      this.state[current].trim() &&
      searchArray(this.state[current], this.state[list]) === -1
    ) {
      this._isMounted &&
        this.setState({
          [list]: [
            ...this.state[list],
            this.state[current].replace(/\b[a-zA-Z]/g, (match) =>
              match.toUpperCase()
            ),
          ],
          [current]: "",
        })
    } else {
      this._isMounted &&
        this.setState({
          [current]: "",
        })
    }
  }

  handleListDelete = (list, current) => {
    // console.log(list)
    this._isMounted &&
      this.setState({
        [list]: this.state[list].filter((e) => {
          return e !== current
        }),
      })
  }

  handleUpdate = () => {
    const { update } = this.props
    scrollToTop()
    this._isMounted && this.setState({ success: false }, update())
  }

  uploadCallback = (r) => {
    if (this._isMounted && r.data.public_id !== undefined) {
      const publicId = r.data.public_id
      const type = r.data.resource_type
      this.setState({
        images: [...this.state.images, { pid: publicId, resourceType: type }],
      })
    }
  }

  uploadCompleteCallback = () => {
    const { images } = this.state
    images.length === 1
      ? this.submitDirectory()
      : this._isMounted && this.setState({ uploadComplete: true })
  }

  closeModal = () => {
    this._isMounted && this.setState({ addImages: false })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this._isMounted &&
      this.setState(({ images }) => ({
        images: arrayMove(images, oldIndex, newIndex),
      }))
  }

  onRemove = (index) => {
    const images = this.state.images
    images.splice(index, 1)
    this._isMounted && this.setState({ images: images })
  }

  getInitialState = () => {
    const { data } = this.props.data
    // console.log(data)
    const attr = data[0].attributes
    // Remove the 'field_' prefix and null values from the attributes
    let attributes = Object.fromEntries(
      Object.entries(attr).map(([key, value]) => [
        key.replace("field_", ""),
        value ? value : "",
      ])
    )

    this._isMounted &&
      this.setState((prevProps) => ({
        ...prevProps,
        id: data[0].id,
        images: JSON.parse(attributes.cloudinary),
        hours: JSON.parse(attributes.hours_of_operation),
        description: attributes.body.value,
        summary: attributes.body.summary,
        name: attributes.title,
        phone: attributes.phone_number,
        ...attributes,
      }))
  }

  updateDirectory = () => {
    let self = this
    const {
      id,
      name,
      images,
      contact,
      phone,
      email,
      address,
      products,
      services,
      description,
      hours,
      url,
      summary,
    } = self.state

    const endpoint = `/jsonapi/node/directory/${id}`

    const payload = {
      data: {
        id: id,
        type: "node--directory",
        attributes: {
          title: name,
          body: {
            value: description,
            format: "plain_text",
            summary: `${truncateString(summary, 140)}`,
          },
          field_cloudinary: JSON.stringify(images),
          field_contact: contact,
          field_phone_number: phone,
          field_email: email,
          field_address: address,
          field_products: products,
          field_services: services,
          field_hours_of_operation: JSON.stringify(hours),
          field_url: url,
        },
      },
    }

    axios
      .patch(baseUrl + endpoint, payload, getAuthHeadersJsonapi())
      .then(function (response) {
        // console.log(response)
        self._isMounted && self.setState({ success: true })
      })
      .catch(function (error) {
        console.log(error.response)
      })
  }

  submitDirectory = () => {
    let self = this
    const {
      name,
      contact,
      phone,
      email,
      address,
      products,
      services,
      description,
      images,
      hours,
      url,
      summary,
    } = self.state

    const params = "/jsonapi/node/directory"

    if (images.length > 0) {
      const payload = {
        data: {
          type: "node--directory",
          attributes: {
            entity_type: "node",
            title: name,
            body: {
              value: description,
              format: "plain_text",
              summary: `${truncateString(summary, 140)}`,
            },
            field_cloudinary: JSON.stringify(images),
            field_contact: contact,
            field_phone_number: phone,
            field_email: email,
            field_address: address,
            field_products: products,
            field_services: services,
            field_hours_of_operation: JSON.stringify(hours),
            field_url: url,
          },
        },
      }

      axios
        .post(baseUrl + params, payload, getAuthHeadersJsonapi())
        .then(function (response) {
          // console.log(response)
          self._isMounted &&
            self.setState({ images: [], success: true, description: "" })
        })
        .catch(function (error) {
          console.log(error.response)
        })
    }
  }

  modalData = () => {
    const { images } = this.state
    return (
      <GridContainer
        spacing={0}
        alignContent={"center"}
        alignItems={"center"}
        justify={"center"}
      >
        <GridItem xs={12}>
          <h3 style={{ textAlign: "center" }}>Add Images</h3>
          <Clearfix />
          <div style={{ marginTop: "30px", textAlign: "center" }}>
            <Upload
              limit={30 - images.length}
              callback={this.uploadCallback}
              completedCallback={this.closeModal}
              buttonText={"Submit"}
              filesText={"Image"}
            />
          </div>
        </GridItem>
      </GridContainer>
    )
  }

  listImages = () => {
    const { images, addImages } = this.state

    const add = (e) => {
      e.preventDefault()
      this._isMounted && this.setState({ addImages: !addImages })
    }

    return (
      <>
        <h3>Media</h3>
        <span style={{ width: "100%", textAlign: "center" }}>
          <Button style={style.buttons} color={"primary"} onClick={add}>
            <FontAwesomeIcon icon={["fal", "plus"]} size={"2x"} /> Images
          </Button>
        </span>
        {images && (
          <GridItem xs={12} height="100%" style={{ textAlign: "center" }}>
            {images.length > 1 && (
              <small>
                Re-order items by clicking/tapping them, then dragging and
                dropping into their new location. Don't forget to save your
                changes below.
              </small>
            )}
            <SortableImages
              images={images}
              onSortEnd={this.onSortEnd}
              onRemove={images.length > 1 ? this.onRemove : null}
              axis="xy"
              distance={1}
            />
          </GridItem>
        )}
        {images.length <= 29 && addImages && (
          <Modal
            data={this.modalData()}
            open={addImages}
            handleModal={this.closeModal}
          />
        )}
      </>
    )
  }

  renderForm = (action) => {
    const {
      description,
      uploadComplete,
      images,
      hours,
      hourErrors,
      path,
    } = this.state
    return (
      <Paper style={style.paper}>
        {action === "edit" ? (
          <GridContainer
            spacing={0}
            alignContent={"center"}
            alignItems={"center"}
            justify={"center"}
          >
            <GridItem
              xs={12}
              style={{ textAlign: "center", paddingBottom: "16px" }}
            >
              <h2>Directory Listing</h2>
              {path != null && <DirectoryLink {...this.props} path={path} />}
            </GridItem>
            <BaseFields values={this.state} handleChange={this.handleChange} />
            <ListFields
              values={this.state}
              handleChange={this.handleChange}
              handleListAdd={this.handleListAdd}
              handleListDelete={this.handleListDelete}
            />
            <Hours
              days={days}
              hours={hours}
              handleChange={this.handleChangeHours}
              errors={hourErrors}
              hourOptions={hourOptions}
            />
            {this.listImages()}
            <GridItem
              style={{ textAlign: "center", marginTop: "20px" }}
              xs={12}
            >
              <hr />
              <Button
                disabled={!hourErrors.every((element) => element === null)}
                style={style.buttons}
                color={"primary"}
                onClick={(e) => {
                  e.preventDefault()
                  this.updateDirectory()
                }}
              >
                Update Directory Listing
              </Button>
              <br />
              <small>
                Changes made may take up to an hour to completely update on our
                system
              </small>
            </GridItem>
          </GridContainer>
        ) : (
          <>
            {!uploadComplete && (
              <GridContainer
                spacing={0}
                alignContent={"center"}
                alignItems={"center"}
                justify={"center"}
              >
                <GridItem xs={12}>
                  <h2 style={{ textAlign: "center" }}>Directory Listing</h2>
                </GridItem>
                <BaseFields
                  values={this.state}
                  handleChange={this.handleChange}
                />
                <ListFields
                  values={this.state}
                  handleChange={this.handleChange}
                  handleListAdd={this.handleListAdd}
                  handleListDelete={this.handleListDelete}
                />
                <Hours
                  days={days}
                  hours={hours}
                  handleChange={this.handleChangeHours}
                  errors={hourErrors}
                  hourOptions={hourOptions}
                />
                <GridItem xs={12} lg={8}>
                  <h3 style={{ textAlign: "center" }}>Images</h3>
                  <Clearfix />
                  <div style={{ marginTop: "30px", textAlign: "center" }}>
                    <Upload
                      limit={10}
                      callback={this.uploadCallback}
                      completedCallback={this.uploadCompleteCallback}
                      buttonText={"Submit"}
                      filesText={"Image"}
                      buttonDisabled={description.length < 8}
                    />
                    <br />
                    <small>
                      If you upload more than one item you will be able to
                      rearrange them in the next step
                    </small>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            {uploadComplete && images.length > 1 && (
              <div style={{ textAlign: "center" }}>
                <h3>Set Media Order</h3>
                <h5>
                  Drag and drop into the desired order then submit your post!
                </h5>
                <SortableImages
                  images={images}
                  onSortEnd={this.onSortEnd}
                  axis="xy"
                />
                <Button
                  disabled={!hourErrors.every((element) => element === null)}
                  color={"primary"}
                  style={style.buttons}
                  onClick={(e) => {
                    e.preventDefault()
                    this.submitDirectory()
                  }}
                >
                  Create Directory Listing
                </Button>
              </div>
            )}
          </>
        )}
      </Paper>
    )
  }

  render() {
    const { success } = this.state
    const { action } = this.props
    const message = `Your Directory Listing has been ${
      action === "edit" ? "updated" : "created"
    }!`
    const subMessage =
      "NOTE: It may take up to 1 hr to completely update on our system."

    return success ? (
      <ModalSuccess
        success={this.state.success}
        update={this.handleUpdate}
        message={message}
        subMessage={subMessage}
      />
    ) : (
      this.renderForm(action)
    )
  }
}

export default DirectoryForm
