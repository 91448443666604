import React, { Component } from "react"
import "./ConversationSearch.css"
import * as Auth from "../../../../utils/Auth"
import axios from "axios"
import Suggestions from "../Suggestions"
import Toolbar from "../Toolbar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const baseUrl = `${process.env.GATSBY_API_URL}`

export default class ConversationSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: "",
      results: [],
    }
    this.addMember = this.addMember.bind(this)
    this.deleteMember = this.deleteMember.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  getUsers = () => {
    if (!Auth.isBrowser) {
      return
    }

    let self = this
    const endpoint = "/jsonapi/user/user"
    const params = `?filter[name][value]=${self.state.query}&filter[name][operator]=CONTAINS&page[limit]=7`
    axios
      .get(baseUrl + endpoint + params, Auth.getAuthHeadersJsonapi())
      .then(response => {
        if (response.data.data.length !== 0) {
          self.setState(prevState => {
            let results = response.data.data.map(result => {
              return {
                id: result.id,
                name: result.attributes.name,
              }
            })
            return { ...prevState, results }
          })
        } else {
          console.log("no response")
        }
      })
      .catch(function(error) {
        // console.log(error)
      })
  }

  handleInputChange = () => {
    this.setState(
      {
        query: this.search.value,
      },
      () => {
        if (this.state.query && this.state.query.length > 1) {
          if (this.state.query.length % 2 === 0) {
            this.getUsers()
          }
        } else if (!this.state.query) {
          this.setState({
            results: [],
          })
        }
      }
    )
  }

  addMember = (id, name) => {
    const members = [
      {
        id: id,
        name: name,
      },
      ...this.props.members,
    ]
    this.setState({
      results: [],
      query: "",
    })
    this.props.memberCallback(members)
    this.search.value = ""
  }

  deleteMember = id => {
    const filtered = this.props.members.filter(function(item) {
      return item.id !== id
    })
    this.setState({
      results: [],
      query: "",
    })
    this.props.memberCallback(filtered)
    this.search.value = ""
  }

  render() {
    const { members, isMobile, handleShowConversations } = this.props
    return (
      <div className="conversation-search">
        <Toolbar
          title="New Message"
          style={{ paddingBottom: "10px" }}
          leftItems={[
            isMobile && (
              <button
                key={"refresh"}
                onClick={() => handleShowConversations(true)}
                className="clear-button"
                type={"submit"}
              >
                <FontAwesomeIcon
                  icon={"arrow-left"}
                  color={"#007aff"}
                  size={"2x"}
                />
              </button>
            ),
          ]}
        />
        <div>
          {members &&
            members.map((users, idx) => (
              <div key={idx} className={"conversation-search-user"}>
                {users.name} &nbsp;
                <button
                  key={idx}
                  onClick={() => this.deleteMember(users.id)}
                  className="clear-button"
                  type={"submit"}
                >
                  <FontAwesomeIcon icon={"times"} color={"#ffffff"} />
                </button>
              </div>
            ))}
        </div>
        <form>
          <input
            placeholder="Search users..."
            ref={input => (this.search = input)}
            onChange={this.handleInputChange}
            className={"conversation-search-input"}
          />
        </form>
        <Suggestions
          results={this.state.results}
          addMember={this.addMember}
          deleteMember={this.deleteMember}
        />
      </div>
    )
  }
}
