import React, { useEffect, useState } from "react"
import axios from "axios"
import DirectoryForm from "./form"
import { getUuid } from "../../utils/Auth"
import { RefreshContent } from "react-js-pull-to-refresh"

const Directory = (props) => {
  const baseUrl = `${process.env.GATSBY_API_URL}`
  const endpoint = "/jsonapi/node/directory"
  const filter = `?filter[uid.id][value]=${getUuid()}`
  const [loaded, setLoaded] = useState(false)
  const [directoryExists, setDirectoryExists] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    getDirectoryListing()
  }, [])

  const getDirectoryListing = () => {
    axios
      .get(baseUrl + endpoint + filter)
      .then((r) => {
        const { count } = r.data.meta
        if (count > 0) {
          setData(r.data)
          setDirectoryExists(true)
          setLoaded(true)
        } else {
          setLoaded(true)
        }
      })
      .catch((e) => {
        console.log(e)
        setLoaded(true)
      })
  }

  const updateData = () => {
    setLoaded(false)
    getDirectoryListing()
  }

  if (!loaded) {
    return <RefreshContent />
  } else {
    return (
      <DirectoryForm
        {...props}
        data={data ? data : null}
        update={updateData}
        action={directoryExists ? "edit" : "new"}
      />
    )
  }
}

export default Directory
