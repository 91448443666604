import React from "react"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import Accordion from "../Accordion/Accordion"
import { Typography } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"

const style = {
  accordionShadow: {
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);",
  },
}

const getDate = (date) => {
  const d = new Date(date)
  return d.toLocaleDateString()
}

const planNames = [
  { id: "cco_marketing_elite", name: "CCO Marketing Elite" },
  { id: "cco_marketing_premium", name: "CCO Marketing Premium" },
  { id: "cco_marketing_plus", name: "CCO Marketing Plus" },
  { id: "cco_directory_listing", name: "CCO Directory Listing" },
]

const getPlanName = (id) => {
  const plan = planNames.find((p) => p.id === id)
  return plan ? plan.name : "CCO Custom Subscription"
}

const Subscriptions = (props) => {
  // console.log(props)
  return (
    <GridContainer spacing={0} alignContent={"center"}>
      <GridItem xs={12}>
        <MySubscriptions {...props} />
      </GridItem>
    </GridContainer>
  )
}

const MySubscriptions = (props) => {
  const { customer } = props
  let countActive = 0
  let subs = []
  if (customer && customer.paymentMethods.length > 0) {
    customer.paymentMethods.map((e) => {
      if (e.subscriptions.length > 0) {
        e.subscriptions
          .sort(function (a, b) {
            if (a.status > b.status) {
              return -1
            }
            if (b.status > a.status) {
              return 1
            }
            return 0
          })
          .reverse()
          .slice(0, 500)
          .map((s) => {
            countActive = s.status === "Active" ? countActive + 1 : countActive
            subs.push({
              id: s.id,
              balance: s.balance,
              status: s.status,
              nextBilling: getDate(s.nextBillingDate),
              plan: getPlanName(s.planId),
              transactions: s.transactions,
            })
          })
      }
    })
  }

  return (
    <>
      {subs.length > 0 ? (
        <>
          {subs.map((s, idx) => (
            <Accordion
              raised
              key={idx}
              style={style.accordionShadow}
              collapses={[
                {
                  title: (
                    <GridContainer
                      style={{ width: "100%" }}
                      spacing={0}
                      alignContent={"center"}
                    >
                      <GridItem xs={12} md={3}>
                        <Text title={"Plan:"} text={s.plan} />
                      </GridItem>
                      <GridItem xs={6} md={2}>
                        <Text title={"ID:"} text={s.id} />
                      </GridItem>
                      <GridItem xs={6} md={2}>
                        <Text title={"Status:"} text={s.status} />
                      </GridItem>
                      <GridItem xs={6} md={2}>
                        <Text title={"Next Bill Date:"} text={s.nextBilling} />
                      </GridItem>
                      <GridItem xs={6} md={2}>
                        <Text title={"Balance:"} text={`$${s.balance}`} />
                      </GridItem>
                    </GridContainer>
                  ),
                  content: <Transactions transactions={s.transactions} />,
                },
              ]}
            />
          ))}
          {countActive === 0 && <AvailablePlans {...props} />}
        </>
      ) : (
        <AvailablePlans {...props} />
      )}
    </>
  )
}

const plans = [
  {
    id: "cco_directory_listing",
    title: "CCO Directory Listing",
    description:
      "Take your existing listing to a new level and make your\n" +
      "name, products and services geographically searchable on\n" +
      "CCO! Make sure to edit your Directory Listing right here in\n" +
      "your Dashboard with the best information and pictures about\n" +
      "your company and its offerings for the best chance to be\n" +
      "found. Your listing will automatically be found with\n" +
      "geographical preference in a effort to help local businesses\n" +
      "thrive!",
    price: "$250/yr",
  },
]

const AvailablePlans = (props) => {
  const { handleSelectedSubscription, selectedSubscription } = props

  return (
    <>
      <Typography
        // style={{ padding: "0 20px" }}
        variant="subtitle1"
        component="h2"
      >
        You currently do not have any active subscriptions. Simply select a plan
        below, enter your contact and billing details and we'll get you set up
        right away.
      </Typography>
      <Paper style={{ marginTop: "20px", padding: "20px" }}>
        <GridContainer>
          {!selectedSubscription && (
            <GridItem xs={12} style={{ paddingTop: "20px" }}>
              <span style={{ color: "red" }}>
                Please select a subscription to continue
              </span>
            </GridItem>
          )}
          <GridItem xs={12} style={{ paddingTop: "20px" }}>
            {plans.map((plan, idx) => {
              const isSelected = selectedSubscription === plan.id
              return (
                <a
                  key={idx}
                  href={"#"}
                  onClick={(e) => {
                    e.preventDefault()
                    handleSelectedSubscription(plan.id)
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <GridContainer>
                    <GridItem xs={9}>
                      <Typography style={{ color: "#00acc1" }} variant="h6">
                        {plan.title}
                      </Typography>
                      <Typography
                        style={{ paddingTop: "10px", color: "grey" }}
                        variant="body2"
                      >
                        {plan.description}
                      </Typography>
                    </GridItem>
                    <GridItem xs={2} style={{ textAlign: "center" }}>
                      <Typography style={{ color: "#00acc1" }} variant="h6">
                        {plan.price}
                      </Typography>
                      <CheckCircleIcon
                        style={{
                          color: isSelected ? "green  " : "grey",
                          fontSize: "4rem",
                          marginTop: "22px",
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </a>
              )
            })}
          </GridItem>
        </GridContainer>
      </Paper>
    </>
  )
}

const Transactions = (props) => {
  const { transactions } = props

  return (
    <>
      {transactions.map((d, idx) => (
        <GridContainer
          key={idx}
          style={{ width: "100%", borderBottom: "1px black" }}
          spacing={0}
          alignContent={"center"}
        >
          <GridItem xs={6} md={2}>
            <Text title={"ID:"} text={d.id} />
          </GridItem>
          <GridItem xs={6} md={2}>
            <Text title={"Status:"} text={d.processorResponseText} />
          </GridItem>
          <GridItem xs={6} md={2}>
            <Text title={"Amount:"} text={d.amount} />
          </GridItem>
          <GridItem xs={6} md={3}>
            <Text title={"Billed On:"} text={getDate(d.createdAt)} />
          </GridItem>
          <GridItem xs={6} md={3}>
            <Text
              title={"Billing Period:"}
              text={`${getDate(
                d.subscription.billingPeriodStartDate
              )} - ${getDate(d.subscription.billingPeriodEndDate)}`}
            />
          </GridItem>
        </GridContainer>
      ))}
    </>
  )
}

const Text = (props) => {
  const { title, text } = props
  return (
    <GridItem xs={12}>
      <Typography variant="body2" color="textSecondary" component="p">
        {title}
      </Typography>
      <Typography
        gutterBottom
        variant="subtitle2"
        component="p"
        style={{ paddingBottom: "14px" }}
      >
        {text}
      </Typography>
    </GridItem>
  )
}

export default Subscriptions
