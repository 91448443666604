import React from "react"
import GridItem from "../Grid/GridItem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CustomInput from "../CustomInput/CustomInput"
import Button from "../CustomButtons/Button"
import Clearfix from "../Clearfix/Clearfix"

const style = {
  ul: {
    listStyle: "none",
    paddingLeft: "0",
  },
  buttons: {
    paddingBottom: "26px",
  },
}

const ListFields = (props) => {
  const { values, handleChange, handleListAdd, handleListDelete } = props
  const fields = [
    { label: "Products", listName: "products", current: "product" },
    { label: "Services", listName: "services", current: "service" },
  ]

  const getPadding = (items) => {
    // Pixels to pad in order to match container heights
    const padPerItem = 44
    const { products, services } = values

    if (products === items && services.length > items.length) {
      return (services.length - items.length) * padPerItem
    }

    if (services === items && products.length > items.length) {
      return (products.length - items.length) * padPerItem
    }

    return 0
  }

  return (
    <>
      {fields.map((f, idx) => (
        <GridItem key={idx} xs={12} sm={6} height="100%">
          <h3 style={{ display: "inline", paddingRight: "10px" }}>
            My {f.label}
          </h3>
          <small>Top 10</small>
          {values[f.listName] && (
            <ul
              style={Object.assign(
                {
                  paddingTop: `${getPadding(values[f.listName])}px`,
                },
                style.ul
              )}
            >
              {values[f.listName].map((item, id) => (
                <li
                  key={id}
                  style={{
                    padding: "10px",
                    backgroundColor: id % 2 ? "#eeeeee" : "#ffffff",
                  }}
                >
                  {item}
                  <a
                    href={"#"}
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault()
                      handleListDelete(f.listName, item)
                    }}
                  >
                    <FontAwesomeIcon icon={["fas", "minus"]} color={"red"} />
                  </a>
                </li>
              ))}
            </ul>
          )}

          <CustomInput
            labelText={f.label}
            formControlProps={{
              fullWidth: true,
              required: false,
            }}
            inputProps={{
              value: values[f.current],
              onChange: handleChange,
              type: "text",
              className: "form-control",
              name: f.current,
              placeholder: `Enter ${f.label}...`,
              autoFocus: false,
              maxLength: 255,
            }}
          />
          <Button
            color={"primary"}
            style={{ float: "right", ...style.buttons }}
            disabled={values[f.listName].length > 9}
            onClick={(e) => {
              e.preventDefault()
              handleListAdd(f.listName, f.current)
            }}
          >
            Add {f.current.toUpperCase()}
          </Button>
        </GridItem>
      ))}
      <Clearfix />
    </>
  )
}
export default ListFields
