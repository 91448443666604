import React from "react"
import GridItem from "../Grid/GridItem"
import CustomInput from "../CustomInput/CustomInput"
import { urlIsValid, emailIsValid } from "../../helpers/common"

const BaseFields = (props) => {
  const { handleChange, values } = props
  const fields = [
    {
      label: "Business Name",
      required: true,
      type: "text",
      name: "name",
      multiline: false,
      rows: 0,
      fullWidth: false,
    },
    {
      label: "Contact Name",
      required: true,
      type: "text",
      name: "contact",
      multiline: false,
      rows: 0,
      fullWidth: false,
    },
    {
      label: "Phone Number",
      required: true,
      type: "text",
      name: "phone",
      multiline: false,
      rows: 0,
      fullWidth: false,
    },
    {
      label: "Email Address",
      required: true,
      type: "email",
      name: "email",
      multiline: false,
      rows: 0,
      fullWidth: false,
    },
    {
      label: "Address (Full or City, State)",
      required: true,
      type: "text",
      name: "address",
      multiline: false,
      rows: 0,
      fullWidth: true,
    },
    {
      label: "Business Summary",
      required: true,
      type: "text",
      name: "summary",
      multiline: true,
      rows: 2,
      fullWidth: true,
      maxLength: 140
    },
    {
      label: "Business Description",
      required: true,
      type: "text",
      name: "description",
      multiline: true,
      rows: 5,
      fullWidth: true,
      maxLength: 5000
    },
    {
      label: "Website URL",
      required: true,
      type: "url",
      name: "url",
      multiline: false,
      rows: 0,
      fullWidth: true,
      pattern: 'pattern="https?://.+"',
    },
  ]

  const eCheck = (name, req, chars) => {
    if (req && chars.length > 0) {
      if (name === "url" && !urlIsValid(chars)) return true
      if (name === "email" && !emailIsValid(chars)) return true
      if (chars.length < 5) return true
    }
    return false
  }

  return (
    <>
      {fields.map((f, idx) => (
        <GridItem key={idx} xs={12} sm={f.fullWidth ? 12 : 6}>
          <CustomInput
            error={eCheck(f.name, f.required, values[f.name])}
            labelText={f.label}
            formControlProps={{
              fullWidth: true,
              // className: classes.customFormControlClasses,
              required: f.required,
            }}
            attributes={{
              maxLength: f.maxLength != null ? f.maxLength : 255,
            }}
            inputProps={{
              value: values[f.name],
              onChange: handleChange,
              type: f.type,
              className: "form-control",
              name: f.name,
              placeholder: `Enter ${f.label}...`,
              multiline: f.multiline,
              autoFocus: false,
              rows: f.rows,
              pattern: f.pattern ? f.pattern : "",
            }}
          />
          {f.name === "description" && (
            <div style={{ float: "right" }}>
              <small>{values[f.name].length} of 5000</small>
            </div>
          )}
          {f.name === "summary" && (
            <div style={{ float: "right" }}>
              <small>{values[f.name].length} of 140</small>
            </div>
          )}
        </GridItem>
      ))}
    </>
  )
}

export default BaseFields
