import React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import arrayMove from "array-move"
import Modal from "./modal"
import { getAuthHeadersJsonapi } from "../../utils/Auth"
import { truncateString } from "../../helpers/common"
// Material UI & Kit
import { Input } from "@material-ui/core"
import Clearfix from "../Clearfix/Clearfix"
import Button from "../CustomButtons/Button"
import Divider from "@material-ui/core/Divider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SortableImages from "../SortableImages"

const baseUrl = `${process.env.GATSBY_API_URL}`
const charLimit = 500
const autoCloseTimeout = 8000

const style = {
  button: {
    fontSize: "14px",
    display: "inline-block",
    height: "36px",
    minWidth: "88px",
    padding: "6px 16px",
    lineHeight: "1.42857143",
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "pointer",
    border: "0",
    borderRadius: "2px",
    background: "rgb(0, 172, 193)",
    color: "#fff",
    outline: "0",
    float: "right",
  },
}

class EditPost extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      id: "",
      images: [],
      message: "",
      open: false,
      success: false,
      uploadComplete: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    const { id, message, fieldImage } = this.props
    this.setState({
      id: id,
      message: message,
      images: fieldImage,
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleModal = command => {
    this._isMounted && this.setState({ open: command })
  }

  handleInputChange(event) {
    this._isMounted &&
      this.setState({
        message: event.target.value,
      })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this._isMounted &&
      this.setState(({ images }) => ({
        images: arrayMove(images, oldIndex, newIndex),
      }))
  }

  updatePost = () => {
    let self = this
    const { id, message, images } = self.state

    const params = "/jsonapi/node/community/" + id

    const payload = {
      data: {
        id: id,
        type: "node--community",
        attributes: {
          title: `${truncateString(message, 50)}`,
          body: {
            value: message,
            format: "plain_text",
            summary: `${truncateString(message, 50)}`,
          },
          field_cloudinary: JSON.stringify(images),
        },
      },
    }

    axios
      .patch(baseUrl + params, payload, getAuthHeadersJsonapi())
      .then(function(response) {
        console.log(response)
        self._isMounted &&
          self.setState({ images: [], success: true, message: "" })
      })
      .catch(function(error) {
        console.log(error.response)
      })
  }

  renderForm = () => {
    const { message, images } = this.state
    return (
      <>
        {images.length > 1 && (
          <div style={{ textAlign: "center", zIndex: "1500" }}>
            <h3>Set Media Order</h3>
            <h5>
              Drag and drop into the desired order, edit your message and submit
              your edited post!
            </h5>
            <SortableImages
              images={images}
              onSortEnd={this.onSortEnd}
              axis="xy"
            />
          </div>
        )}
        <Divider />
        <Clearfix />
        <div style={{ float: "right", paddingTop: "10px" }}>
          {message.length >= 8 && (
            <FontAwesomeIcon
              color={"green"}
              icon={["fas", "check"]}
              size={"2x"}
            />
          )}
        </div>
        <Clearfix />
        <div style={{ textAlign: "center" }}>
          <h3>Edit Message</h3>
          <Input
            type="text"
            value={message}
            onChange={this.handleInputChange}
            placeholder={"Some great story, build or thought here..."}
            style={{ width: "100%" }}
            required={true}
            inputProps={{ maxLength: charLimit }}
            multiline={true}
            rows={5}
          />
        </div>
        <div style={{ float: "right" }}>
          <small>
            8 character min. {message.length} of {charLimit}
          </small>
        </div>
        <Clearfix />
        <Button
          disabled={message.length < 8}
          onClick={e => {
            e.preventDefault()
            this.updatePost()
          }}
          style={style.button}
        >
          Submit Edited Post
        </Button>
      </>
    )
  }

  renderStatusMessage = () => {
    setTimeout(() => {
      this._isMounted && this.setState({ open: false, success: false })
    }, autoCloseTimeout)
    return (
      <>
        <h3>Congratulations on your successfully edited post! </h3>
        <p>
          {" "}
          If you just posted it and are making quick changes it could be up to
          date immediately, but please note that it may take up to an hour to
          completely update your changes.
        </p>
        <small style={{ float: "right" }}>
          This window will close automatically in {autoCloseTimeout / 1000}{" "}
          seconds
        </small>
      </>
    )
  }

  render() {
    const { open, success } = this.state
    const { icon } = this.props
    return (
      <Modal
        size={"1x"}
        // color={color}
        icon={icon}
        open={open}
        handleModal={this.handleModal}
        data={success ? this.renderStatusMessage() : this.renderForm()}
      />
    )
  }
}

export default EditPost
